import useAuthContext from "../auth/useAuthContext";
import styles from "../../styles/admin/AdminDashboard.module.css";

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import DebugPage from "./DebugPage";
import ServiceStatusPage from "./ServiceStatusPage";
import FlagsPage from "./FlagsPage";
import UsersPage from "./UsersPage";

export function Card(props) {
    const { title, body } = props;

    return (
        <div key={title} className={styles.card}>
            <div className={styles.card_title}>{title}</div>
            <div className={styles.card_body}>{body}</div>
        </div>
    );
}

export default function AdminDashboard() {
    const { handleFetchAdminDashboard, handleFetchFeatureFlags, handleFetchServiceStatus } = useAuthContext();
    const [activeTab, setActiveTab] = useState("users");

    const [dashboardData, setDashboardData] = useState({});
    const [featureFlagsData, setFeatureFlagsData] = useState({});
    const [flagsRefreshKey, setFlagsRefreshKey] = useState(0);
    const [serviceStatusData, setServiceStatusData] = useState({});
    const [serviceStatusRefreshKey, setServiceStatusRefreshKey] = useState(0);

    useEffect(() => {
        handleFetchAdminDashboard()
            .then((response) => {
                setDashboardData(response.data.data);
            })
            .catch((error) => toast.error(error?.response?.data?.error || error?.response?.data?.detail));
        // TODO (Ajay): Django default error returns in the "detail" field but we chose to name it "error" in
        // our custom error response. We should standardize this.
    }, [handleFetchAdminDashboard]);

    useEffect(() => {
        handleFetchFeatureFlags()
            .then((response) => {
                // Sort feature flags by created_at datetime field
                const flags = response.data.flags;
                flags.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                setFeatureFlagsData({ flags: flags });
            })
            .catch((error) => toast.error(error?.response?.data?.error || error?.response?.data?.detail));
    }, [handleFetchFeatureFlags, flagsRefreshKey]);

    useEffect(() => {
        handleFetchServiceStatus("llm_provider")
            .then((response) => {
                setServiceStatusData(response.data.service_status);
            })
            .catch((error) => toast.error(error?.response?.data?.error || error?.response?.data?.detail));
    }, [handleFetchServiceStatus, serviceStatusRefreshKey]);

    if (Object.keys(dashboardData).length === 0) {
        return null;
    }

    return (
        <div className={styles.admin_dashboard_container}>
            <h1>Admin Dashboard</h1>
            <div className={styles.tabs_container}>
                <div
                    className={`${styles.tab} ${activeTab === "users" && styles.active_tab}`}
                    onClick={() => setActiveTab("users")}
                >
                    Users
                </div>
                <div
                    className={`${styles.tab} ${activeTab === "flags" && styles.active_tab}`}
                    onClick={() => setActiveTab("flags")}
                >
                    Flags
                </div>
                <div
                    className={`${styles.tab} ${activeTab === "service_status" && styles.active_tab}`}
                    onClick={() => setActiveTab("service_status")}
                >
                    Service Status
                </div>
                <div
                    className={`${styles.tab} ${activeTab === "debug" && styles.active_tab}`}
                    onClick={() => setActiveTab("debug")}
                >
                    Debug
                </div>
            </div>
            <div className={styles.admin_dashboard}>
                {activeTab === "users" && <UsersPage dashboardData={dashboardData} />}
                {activeTab === "flags" && (
                    <FlagsPage featureFlagsData={featureFlagsData} setRefreshKey={setFlagsRefreshKey} />
                )}
                {activeTab === "service_status" && (
                    <ServiceStatusPage
                        serviceStatusData={serviceStatusData}
                        setRefreshKey={setServiceStatusRefreshKey}
                    />
                )}
                {activeTab === "debug" && <DebugPage />}
            </div>
        </div>
    );
}
