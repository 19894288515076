import { useEffect, useState } from "react";
import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import useApplicationContext from "../useApplicationContext";
import { ReactComponent as CopyIcon } from "../../../imgs/copy-icon.svg";
import { ReactComponent as ThumbUpIcon } from "../../../imgs/thumb-up-icon.svg";
import { ReactComponent as ThumbDownIcon } from "../../../imgs/thumb-down-icon.svg";
import { ReactComponent as ThumbUpFilledIcon } from "../../../imgs/thumb-up-filled-icon.svg";
import { ReactComponent as ThumbDownFilledIcon } from "../../../imgs/thumb-down-filled-icon.svg";
import { ReactComponent as CheckIcon } from "../../../imgs/check-icon.svg";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../base/Tooltip";
import { createGoogleDocCommentFeedback } from "../../../api/apiCalls";
import { toast } from "react-toastify";

function Comment({ comment, reportCardV2ID }) {
    const [reaction, setReaction] = useState(null);
    const [isCopyClicked, setIsCopyClicked] = useState(false);
    const { currentRequestId } = useApplicationContext();

    if (reportCardV2ID === undefined) {
        reportCardV2ID = currentRequestId;
    }

    const handleLikeReaction = () => {
        const formData = {
            highlighted_text: comment.highlightedText,
            comment: comment.comment,
            report_card_v2_id: reportCardV2ID,
        };
        if (reaction === "like") {
            formData.user_likes_comment = null;
            setReaction(null);
        } else {
            formData.user_likes_comment = true;
            setReaction("like");
        }
        createGoogleDocCommentFeedback(formData)
            .then(() => {
                toast.success("Response recorded");
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
    };

    const handleDislikeReaction = () => {
        const formData = {
            highlighted_text: comment.highlightedText,
            comment: comment.comment,
            report_card_v2_id: reportCardV2ID,
        };
        if (reaction === "dislike") {
            formData.user_likes_comment = null;
            setReaction(null);
        } else {
            formData.user_likes_comment = false;
            setReaction("dislike");
        }
        createGoogleDocCommentFeedback(formData)
            .then(() => {
                toast.success("Response recorded");
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(comment.comment);
        setIsCopyClicked(true);
        setTimeout(() => {
            setIsCopyClicked(false);
        }, 2000);
    };

    // AI could return highlighted text with or without quotes
    const ensureQuoted = (input) => {
        // Remove quotes from the start and end if they exist
        const stripped = input.replace(/^['"]|['"]$/g, "");
        // Add quotes back
        return `"${stripped}"`;
    };

    return (
        <div className={styles.comment} data-docx-li data-docx-spacing-after={300}>
            <p className={styles.quote} data-docx-i>
                {ensureQuoted(comment.highlightedText)}
            </p>
            <p className={styles.feedback} data-docx-break={2}>
                {comment.comment}
            </p>
            <div className={styles.comment_action_bar}>
                <Tooltip placement="bottom">
                    <TooltipTrigger className={styles.icon_button} onClick={handleLikeReaction}>
                        {reaction === "like" ? (
                            <ThumbUpFilledIcon className={styles.icon} />
                        ) : (
                            <ThumbUpIcon className={styles.icon} />
                        )}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p className={styles.modal_info_tooltip_text}>Good response</p>
                    </TooltipContent>
                </Tooltip>
                <Tooltip placement="bottom">
                    <TooltipTrigger className={styles.icon_button} onClick={handleDislikeReaction}>
                        {reaction === "dislike" ? (
                            <ThumbDownFilledIcon className={styles.icon} />
                        ) : (
                            <ThumbDownIcon className={styles.icon} />
                        )}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p className={styles.modal_info_tooltip_text}>Bad response</p>
                    </TooltipContent>
                </Tooltip>
                <Tooltip placement="bottom">
                    <TooltipTrigger className={styles.icon_button} onClick={handleCopy}>
                        {isCopyClicked ? <CheckIcon className={styles.icon} /> : <CopyIcon className={styles.icon} />}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p className={styles.modal_info_tooltip_text}>{isCopyClicked ? "Copied!" : "Copy"}</p>
                    </TooltipContent>
                </Tooltip>
            </div>
        </div>
    );
}

export default function GoogleDocComments(props) {
    const { v2ModelResponseJSON } = useApplicationContext();
    const [comments, setComments] = useState([]);

    // We'll use this section in both current submission and previous submissions
    // For current submission, we'll leave props empty and use the v2ModelResponseJSON from application context.
    // For previous submissions, we'll pass the modelResponseJSON as props.
    let modelResponseJSON = props.modelResponseJSON;
    if (modelResponseJSON === undefined) {
        modelResponseJSON = v2ModelResponseJSON;
    }

    const commentsList = comments?.map((comment, index) => (
        <Comment comment={comment} key={index} reportCardV2ID={props.reportCardV2ID} />
    ));
    useEffect(() => {
        if (Object.keys(modelResponseJSON).length < 0) {
            return;
        }

        const formattedComments = modelResponseJSON.google_doc_comments?.map((comment) => {
            return {
                highlightedText: comment.highlighted_text,
                comment: comment.comment,
            };
        });
        setComments(formattedComments);
    }, [modelResponseJSON]);
    return (
        <div className={styles.comments_container}>
            <h3 className={styles.comments_title}>In-line Comments</h3>
            <div className={styles.comments_body} data-docx-ul>
                {commentsList?.length > 0 ? (
                    commentsList
                ) : (
                    <p className={styles.comments_notice}>No comments available</p>
                )}
            </div>
        </div>
    );
}
