import styles from "../../styles/base/Dropdown.module.css";
import ChevronDownIcon from "../../imgs/chevron-down-icon.png";
import { ReactComponent as CloseIcon } from "../../imgs/close-icon.svg";
import { useEffect, useRef, useState } from "react";

function DropdownItem(props) {
    const { onClick, dropdownItem } = props;

    return (
        <div className={styles.dropdown_item} onClick={onClick}>
            <span className={styles.dropdown_item_name}>{dropdownItem?.name}</span>
            {dropdownItem?.tag && <div className={styles.dropdown_item_tag}>{dropdownItem.tag}</div>}
        </div>
    );
}

/*
 * Dropdown component that displays a list of items and allows the user to select one
 * @param {boolean} showDropdown - whether the dropdown is currently displayed
 * @param {function} setShowDropdown - function to toggle the dropdown
 * @param {array} items - list of items to display in the dropdown
 * @param {object} selectedItem - the currently selected item
 * @param {function} setSelectedItem - function to set the selected item
 * @param {object} dropdownRef - reference to the dropdown container
 * @param {string} previewPrefix - text to display before the selected item
 * @param {string} placeholder - text to display when no item is selected
 * @param {boolean} disabled - whether the dropdown is disabled
 * @param {boolean} searchable - whether the dropdown is searchable
 * @param {object} searchTerms - list of search terms to filter the dropdown items
 * ^^^^ ok this was all generated but it looks kinda cool
 *
 * items is an array of objects with the following properties:
 * - name: the name of the item
 * - tag (optional): a tag for the item
 * - metadata (optional): additional metadata if you need to attach other data
 *
 * searchTerms is a mapping of items and their search terms
 */
export default function Dropdown(props) {
    const {
        showDropdown,
        setShowDropdown,
        items,
        selectedItem,
        setSelectedItem,
        dropdownRef,
        previewPrefix,
        placeholder,
        disabled,
        searchable = false,
        searchTermMapping = {},
        allowClear = false,
    } = props;
    const [dropdownTopOffset, setDropdownTopOffset] = useState(null); // null | number
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (dropdownRef.current) {
            const dropdownContainerHeight = dropdownRef.current.clientHeight;
            setDropdownTopOffset(dropdownContainerHeight);
        }
    }, [dropdownRef]);

    // Focus on search input when dropdown is opened
    const inputRef = useRef(null);
    useEffect(() => {
        if (searchable && showDropdown && inputRef.current) {
            inputRef.current.focus();
        }
    }, [searchable, showDropdown]);

    const handleDropdownItemClick = (item) => {
        setSelectedItem(item);
        setShowDropdown((prevDropdownState) => !prevDropdownState);
    };

    const filteredItems = items.filter((item) => {
        const searchTerms = searchTermMapping[item.name] || [];
        return (
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            searchTerms.some((term) => term.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    const dropdownSearchBar = (
        <div className={styles.dropdown_search_container}>
            <input
                className={styles.dropdown_search_input}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                ref={inputRef}
            />
            {searchTerm && (
                <CloseIcon className={styles.dropdown_search_clear_icon} onClick={() => setSearchTerm("")} />
            )}
        </div>
    );
    const dropdownItemsList = (
        <div className={styles.dropdown_item_list}>
            {filteredItems.length > 0 ? (
                filteredItems?.map((item, index) => {
                    return (
                        <DropdownItem key={index} onClick={() => handleDropdownItemClick(item)} dropdownItem={item} />
                    );
                })
            ) : (
                <div className={styles.dropdown_no_results_placeholder}>No results found</div>
            )}
        </div>
    );
    const dropdownContent = (
        <div
            className={styles.dropdown_content}
            style={dropdownTopOffset !== null ? { top: `${dropdownTopOffset + 4}px` } : {}}
        >
            {items.length > 0 ? (
                <>
                    {searchable && dropdownSearchBar}
                    {dropdownItemsList}
                </>
            ) : (
                <span className={styles.dropdown_no_results_placeholder}>No items to display</span>
            )}
        </div>
    );

    if (disabled) {
        return (
            <div className={styles.disabled_dropdown_container} ref={dropdownRef}>
                <div className={styles.disabled_dropdown_preview}>
                    <span className={styles.dropdown_placeholder}>{placeholder}</span>
                    <img
                        className={`${styles.dropdown_arrow} ${showDropdown && styles.rotated_dropdown_arrow}`}
                        src={ChevronDownIcon}
                        alt="arrow"
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.dropdown_container} ref={dropdownRef}>
            <div
                className={styles.dropdown_preview}
                onClick={() => setShowDropdown((prevDropdownState) => !prevDropdownState)}
            >
                <span className={styles.dropdown_preview_content}>
                    {previewPrefix && <span className={styles.dropdown_preview_prefix}>{previewPrefix}</span>}
                    {selectedItem?.name || <span className={styles.dropdown_placeholder}>{placeholder}</span>}
                </span>
                <div className={styles.dropdown_preview_icons}>
                    {allowClear && selectedItem && showDropdown && (
                        <CloseIcon
                            className={styles.dropdown_clear_icon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedItem(null);
                            }}
                        />
                    )}
                    <img
                        className={`${styles.dropdown_arrow} ${showDropdown && styles.rotated_dropdown_arrow}`}
                        src={ChevronDownIcon}
                        alt="arrow"
                    />
                </div>
            </div>
            {showDropdown && dropdownContent}
        </div>
    );
}
