import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";

export const STATIC_ATHENA_NAMESPACE_UUID = "e4c8ed73-0adb-44c5-8936-edd2a97a17d7";

export const CUSTOM_PROMPT_TITLE_MAX_LENGTH_CHARS = 300;

// Avoid potential abuse of people putting a bunch of tokens in and racking up OpenAI expenses.
export const ESSAY_MAX_LENGTH_CHARS = 4500; // 650 words = ~3055 characters
export const PROMPT_MAX_LENGTH_CHARS = 1500; // 150 words (guesstimate) = ~705 characters
export const DEFAULT_TEXTAREA_MAX_LENGTH_CHARS = 1500; // just a default value for textareas

// Sync with backend/backend/utils/constants.py
export const EDITOR_MAX_LENGTH_CHARS = 10000; // Safe limit for prompts that require longer responses (e.g. 2 pages)

export const DEFAULT_ERROR_MESSAGE = "Something went wrong on our end. Please try again later.";

export function FormatIECGradingModelResponse(modelResponse) {
    if (modelResponse === null || modelResponse === undefined) {
        return "";
    }
    return modelResponse
        .replace(/: Excellent/g, `: <span class="${styles["rating_excellent"]}">Excellent</span>`)
        .replace(/: Okay/g, `: <span class="${styles["rating_okay"]}">Okay</span>`)
        .replace(/: Needs Work/g, `: <span class="${styles["rating_needs_work"]}">Needs Work</span>`)
        .replace(/\n/g, "\n\n");
}

export function FormatModelResponse(modelResponse) {
    if (modelResponse === null || modelResponse === undefined) {
        return "";
    }
    return modelResponse.replace(/\n/g, "\n\n");
}

export function ExtractErrorMessage(error) {
    return error.response && error.response.data && error?.response?.data?.error
        ? error?.response?.data?.error
        : DEFAULT_ERROR_MESSAGE;
}

export function generateNiceTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hour = String(now.getHours()).padStart(2, "0");
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}_${hour}-${minute}-${second}`;
}

export function generateReadableHeaderTimestamp() {
    const now = new Date();
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });

    const dateParts = dateFormatter.format(now);
    const timeParts = timeFormatter.format(now);

    // Result: "December 6, 2024, 2:05 PM"
    return `${dateParts}, ${timeParts}`;
}
