import { Card } from "../admin/AdminDashboard";
import form_styles from "../../styles/forms/Forms.module.css";
import styles from "../../styles/admin/AdminDashboard.module.css";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import BaseButton from "../base/BaseButton";
import Dropdown from "../base/Dropdown";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
    createConsultantOrganization,
    createSpecialUserSetup,
    fetchAllConsultantOrganizations,
    fetchLicenses,
} from "../../api/apiCalls";

function CreateConsultantOrganization() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isAdminSeesAllStudents, setIsAdminSeesAllStudents] = useState(null);
    const [licenseEndDate, setLicenseEndDate] = useState(new Date());

    const dropdownRef = useRef(null);
    const isAdminSeesAllStudentsOptions = [
        { name: "Yes", value: true },
        { name: "No", value: false },
    ];
    const isAdminSeesAllStudentsDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={isAdminSeesAllStudentsOptions}
            dropdownRef={dropdownRef}
            selectedItem={isAdminSeesAllStudentsOptions.find((item) => item.value === isAdminSeesAllStudents)}
            setSelectedItem={(item) => setIsAdminSeesAllStudents(item.value)}
            placeholder={"If you're unsure, default to yes."}
        />
    );

    const shouldDisableButton = isAdminSeesAllStudents === null || licenseEndDate === null;

    const handleAddOrganizationClick = (event) => {
        event.preventDefault();

        const formData = {
            name: event.target.organizationName.value,
            num_licenses: event.target.numLicenses.value,
            is_admin_sees_all_students: isAdminSeesAllStudents,
            end_date: licenseEndDate.toISOString().split("T")[0],
        };

        createConsultantOrganization(formData)
            .then((response) => {
                toast.success("Organization created successfully!");
            })
            .catch((error) => {
                toast.error("Error creating organization. Look at the backend logs for more information.");
            });
    };

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddOrganizationClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="organizationName">Organization Name</label>
                    <input id="organizationName" type="text" placeholder="Riley Baker" required />
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="numLicenses"># of Athena Student Licenses</label>
                        <input id="numLicenses" type="number" placeholder="10" required />
                    </div>
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectLicenseEndDate">License End Date</label>
                    <DatePicker
                        className={form_styles.mimic_input}
                        selected={licenseEndDate}
                        onChange={(date) => setLicenseEndDate(date)}
                    />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectIsAdminSeesAllStudents">
                        Should all IECs be able to view all students at the firm?
                    </label>
                    {isAdminSeesAllStudentsDropdown}
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Add Organization
                </BaseButton>
            </form>
        </div>
    );
}

function AddIndividualToExistingConsultantOrganization() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [consultantOrganizations, setConsultantOrganizations] = useState([]);
    const [selectedConsultantOrganization, setSelectedConsultantOrganization] = useState(null);

    useEffect(() => {
        fetchAllConsultantOrganizations()
            .then((response) => {
                setConsultantOrganizations(response?.data.organizations);
            })
            .catch((error) => {
                toast.error("Error fetching all consultant organizations. See backend logs for more information.");
            });
    }, []);

    const dropdownRef = useRef(null);
    const allConsultantOrganizationsDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={consultantOrganizations}
            dropdownRef={dropdownRef}
            selectedItem={selectedConsultantOrganization}
            setSelectedItem={setSelectedConsultantOrganization}
            placeholder={"Select the IEC firm to add this email address to."}
        />
    );

    const shouldDisableButton = selectedConsultantOrganization === null;

    const handleAddIndividualClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailAddress.value,
            setup_flow_type: "consultant",
            metadata: {
                organization_id: selectedConsultantOrganization.id,
            },
        };

        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success("Individual added to IEC firm successfully!");
            })
            .catch((error) => {
                toast.error("Error adding consultant to organization. See backend logs for more information.");
            });
    };

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddIndividualClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailAddress">Email Address</label>
                    <input id="emailAddress" type="email" placeholder="first@iec.com" required />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectConsultantOrganization">Consultant Organization</label>
                    {allConsultantOrganizationsDropdown}
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Add Individual to Organization
                </BaseButton>
            </form>
        </div>
    );
}

function SetAsHighSchoolCounselor() {
    const handleClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailAddress.value,
            setup_flow_type: "high_school_counselor",
            metadata: {},
        };

        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success("Email provisioned as high school counselor successfully!");
            })
            .catch((error) => {
                toast.error("Error provisioning high school counselor. See backend logs for more information.");
            });
    };

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailAddress">Email Address</label>
                    <input id="emailAddress" type="email" placeholder="first@iec.com" required />
                </div>
                <BaseButton className={form_styles.form_button}>Provision</BaseButton>
            </form>
        </div>
    );
}

function CreatePilot() {
    const [pilotStartDate, setPilotStartDate] = useState(new Date());
    const [pilotEndDate, setPilotEndDate] = useState(new Date());
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const dropdownRef = useRef(null);
    const membershipPlanOptions = [
        // { name: "Starter", value: "starter" },
        { name: "Lite", value: "lite" },
        { name: "Pro", value: "pro" },
    ];
    const membershipPlanDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={membershipPlanOptions}
            dropdownRef={dropdownRef}
            selectedItem={selectedPlan}
            setSelectedItem={setSelectedPlan}
            placeholder={"Select Membership Plan"}
        />
    );
    const shouldDisableButton = selectedPlan === null || pilotStartDate === null || pilotEndDate === null;

    const handleCreatePilotClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailSuffix.value,
            setup_flow_type: "pilot",
            metadata: {
                membership_plan: selectedPlan.value,
                start_date: pilotStartDate.toISOString().split("T")[0],
                end_date: pilotEndDate.toISOString().split("T")[0],
            },
        };

        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success(`Pilot created successfully for ${formData.email}!`);
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error creating pilot. Look at the backend logs for more information.");
            });
    };

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleCreatePilotClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailSuffix">Email Suffix</label>
                    <input id="emailSuffix" type="text" placeholder="@student.smusd.us" required />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectMembershipPlan">Membership Plan</label>
                    {membershipPlanDropdown}
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectStartDate">Pilot Start Date</label>
                        <DatePicker
                            className={form_styles.mimic_input}
                            selected={pilotStartDate}
                            onChange={(date) => setPilotStartDate(date)}
                        />
                    </div>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectEndDate">Pilot End Date</label>
                        <DatePicker
                            className={form_styles.mimic_input}
                            selected={pilotEndDate}
                            onChange={(date) => setPilotEndDate(date)}
                        />
                    </div>
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Create Pilot
                </BaseButton>
            </form>
        </div>
    );
}

function CreateLicense({ setRefreshKey }) {
    const [licenseStartDate, setLicenseStartDate] = useState(new Date());
    const [licenseEndDate, setLicenseEndDate] = useState(new Date());
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const dropdownRef = useRef(null);

    const shouldDisableButton = selectedPlan === null || licenseStartDate === null || licenseEndDate === null;

    const handleCreateLicenseClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.emailSuffix.value,
            setup_flow_type: "license",
            metadata: {
                membership_plan: selectedPlan.value,
                num_licenses: parseInt(event.target.numLicenses.value),
                remaining_licenses: parseInt(event.target.numLicenses.value),
                start_date: licenseStartDate.toISOString().split("T")[0],
                end_date: licenseEndDate.toISOString().split("T")[0],
                point_of_contact_email: event.target.pointOfContact.value,
            },
        };

        createSpecialUserSetup(formData)
            .then((response) => {
                toast.success(`License created successfully for ${formData.email}!`);
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error creating license. Look at the backend logs for more information.");
            });
    };

    const membershipPlanOptions = [
        { name: "Lite", value: "lite" },
        { name: "Pro", value: "pro" },
    ];
    const membershipPlanDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={membershipPlanOptions}
            dropdownRef={dropdownRef}
            selectedItem={selectedPlan}
            setSelectedItem={setSelectedPlan}
            placeholder={"Select Membership Plan"}
        />
    );

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleCreateLicenseClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="emailSuffix">Email Suffix</label>
                    <input id="emailSuffix" type="text" placeholder="@student.smusd.us" required />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="selectMembershipPlan">Membership Plan</label>
                    {membershipPlanDropdown}
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="numLicenses">Number of Licenses</label>
                    <input id="numLicenses" type="number" placeholder="10" required />
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectStartDate">License Start Date</label>
                        <DatePicker
                            className={form_styles.mimic_input}
                            selected={licenseStartDate}
                            onChange={(date) => setLicenseStartDate(date)}
                        />
                    </div>
                    <div className={form_styles.form_section}>
                        <label htmlFor="selectEndDate">License End Date</label>
                        <DatePicker
                            className={form_styles.mimic_input}
                            selected={licenseEndDate}
                            onChange={(date) => setLicenseEndDate(date)}
                        />
                    </div>
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="pointOfContact">Point of Contact Email</label>
                    <input id="pointOfContact" type="email" placeholder="john.doe@example.com" required />
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Create License
                </BaseButton>
            </form>
        </div>
    );
}

function LicenseDashboard({ licenses, refreshKey }) {
    if (!licenses || licenses.length === 0) {
        return <div>No licenses found</div>;
    }

    return (
        <div>
            {licenses.map((license) => (
                <div className={styles.license_container} key={license.id}>
                    <p className={styles.list_title}>License for: {license.email_suffix}</p>
                    <ul className={styles.list}>
                        <li>Membership Plan: {license.metadata.membership_plan}</li>
                        <li>Number of Licenses: {license.metadata.num_licenses}</li>
                        <li>Remaining Licenses: {license.metadata.remaining_licenses}</li>
                        <li>Start Date: {license.metadata.start_date}</li>
                        <li>End Date: {license.metadata.end_date}</li>
                        <li>Point of Contact Email: {license.metadata.point_of_contact_email}</li>
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default function AdminB2BActions() {
    const [licenses, setLicenses] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        fetchLicenses()
            .then((response) => {
                setLicenses(response?.data?.licenses || []);
            })
            .catch((error) => {
                console.error(error);
                toast.error("Error fetching licenses. See backend logs for more information.");
            });
    }, [refreshKey]);

    return (
        <div className={form_styles.form_page_container}>
            <div className={form_styles.page_description}>
                <h1 className={form_styles.title}>Admin B2B Actions</h1>
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Add New IEC Organization"} body={<CreateConsultantOrganization />} />
            </div>
            <div className={form_styles.page_content}>
                <Card
                    title={"Add Individual to Existing IEC Organization"}
                    body={<AddIndividualToExistingConsultantOrganization />}
                />
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Create High School Pilot"} body={<CreatePilot />} />
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Licenses"} body={<LicenseDashboard licenses={licenses} refreshKey={refreshKey} />} />
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Create License"} body={<CreateLicense setRefreshKey={setRefreshKey} />} />
            </div>
            <div className={form_styles.page_content}>
                <Card title={"Set as High School Counselor"} body={<SetAsHighSchoolCounselor />} />
            </div>
        </div>
    );
}
