import { useContext, useEffect, useRef, useState } from "react";
import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import useUserContext from "../../auth/useUserContext";
import useApplicationContext from "../useApplicationContext";
import { SidebarContext } from "../../sidebar/SidebarContext";
import { LightweightResponseFeedback } from "../../main/subcomponents/ResponseFeedback";
import InterpretFeedbackHyperlink from "../../main/subcomponents/InterpretFeedbackHyperlink";
import GreenCheckMark from "../../main/subcomponents/GreenCheckMark";
import LoadingSection from "../../main/subcomponents/LoadingSection";

import ReactMarkdown from "react-markdown";

import ChevronDownIcon from "../../../imgs/chevron-down-icon.png";
import ChevronRightIcon from "../../../imgs/chevron-right-icon.png";
import ChevronUpIcon from "../../../imgs/chevron-up-icon.png";
import DoubleChevronRightIcon from "../../../imgs/double-chevron-right-icon.png";
import NoEssayFeedback from "../../../imgs/no-essay-feedback.png";
import BaseButton from "../../base/BaseButton";
import BlueZapIcon from "../../../imgs/blue-zap-icon.png";
import GradeIcon from "../../../imgs/grade-icon.png";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { doesAthenaProModelExist, getHistoryItem } from "../../../api/apiCalls";

import { FormatModelResponse } from "../../main/utils/utils";

function EssayFeedback(props) {
    const { errorMessageRef } = props;
    const { frontendActiveProfile } = useUserContext();
    const {
        applicationInfo,
        modelResponse,
        gradeErrorMessage,
        handleSubmitGradeRequest,
        isEssaySubmissionLoading,
        currentRequestId,
        editorStateStr,
    } = useApplicationContext();
    const navigate = useNavigate();

    const handleGradeClick = () => {
        try {
            handleSubmitGradeRequest(editorStateStr);
        } catch (error) {
            toast.error(error.message);
            return;
        }
    };

    const [doesProModelExist, setDoesProModelExist] = useState(false);

    useEffect(() => {
        doesAthenaProModelExist(applicationInfo.schoolCode)
            .then((response) => {
                setDoesProModelExist(response.data.does_pro_model_exist);
            })
            .catch((error) => {
                toast.error(`Error checking for Athena Pro models: ${error?.response?.data?.error}`);
                setDoesProModelExist(false); // Default to false if there's an error
            });
    }, [applicationInfo.schoolCode]);

    const isProUser = frontendActiveProfile.membership_plan === "pro";

    return (
        <div className={styles.essay_feedback_container}>
            {modelResponse || isEssaySubmissionLoading || gradeErrorMessage ? (
                <div className={styles.essay_feedback}>
                    <div className={styles.essay_feedback_header}>
                        {doesProModelExist ? (
                            <div className={styles.sidebar_pro_upsell_container}>
                                {isProUser ? (
                                    <div className={styles.pro_user_notice}>
                                        <p>You're currently getting school-specific feedback for</p>
                                        <p>
                                            🎉
                                            <strong>{applicationInfo.schoolName}</strong>
                                            🎉
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <span className={styles.sidebar_pro_upsell}>
                                            Want to use our tailored model for{" "}
                                            <strong>{applicationInfo.schoolName}</strong>?
                                        </span>
                                        <BaseButton
                                            className={styles.upgrade_button}
                                            onClick={() => navigate("/pricing")}
                                        >
                                            <img
                                                className={styles.action_button_icon}
                                                src={BlueZapIcon}
                                                alt="upgrade icon"
                                            />
                                            <div className={styles.action_button_text}>Upgrade to Pro</div>
                                        </BaseButton>
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className={styles.sidebar_section_title}>Athena's feedback</div>
                        <div className={styles.sidebar_section_subtitle}>
                            {/* TODO (Eugene): Make this less jank */}
                            Graded using Athena's {doesProModelExist && isProUser ? "Pro" : "Flagship"} Model
                        </div>
                        {/* <div className={styles.essay_feedback_timestamp}>June 4, 1969, 4:20 PM</div> we don't need this to instantly display */}
                    </div>
                    <div className={styles.loading_image}>
                        {isEssaySubmissionLoading ? (
                            <LoadingSection shouldShowText={true} />
                        ) : (
                            modelResponse && <GreenCheckMark />
                        )}
                    </div>
                    {modelResponse && (
                        <div className={styles.essay_feedback_content}>
                            <InterpretFeedbackHyperlink />
                            <GradeDisclaimer />
                            <div className={styles.divider} />
                            <div className={styles.model_response_container}>
                                <ReactMarkdown>{FormatModelResponse(modelResponse)}</ReactMarkdown>
                            </div>
                        </div>
                    )}
                    {gradeErrorMessage && (
                        <div ref={errorMessageRef} className={styles.grade_error_message}>
                            {gradeErrorMessage}
                        </div>
                    )}

                    {modelResponse && (
                        <>
                            <div className={styles.divider} />
                            <LightweightResponseFeedback currentRequestId={currentRequestId} />
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.no_essay_feedback}>
                    <img src={NoEssayFeedback} className={styles.no_essay_feedback_img} alt="no feedback" />
                    <h3 className={styles.no_essay_feedback_title}>No feedback yet</h3>
                    <p className={styles.no_essay_feedback_text}>
                        Submit your draft when you're ready to receive personalized feedback. Ready to start?
                    </p>
                    <BaseButton className={styles.grade_button} onClick={handleGradeClick}>
                        <img className={styles.grade_icon} src={GradeIcon} alt="Grade Icon" />
                        Grade
                    </BaseButton>
                </div>
            )}
        </div>
    );
}

function GradeDisclaimer(props) {
    const [showDisclaimer, setShowDisclaimer] = useState(false);

    const handleShowDisclaimer = () => {
        setShowDisclaimer((prevState) => !prevState);
    };

    return (
        <div className={styles.note_container} onClick={handleShowDisclaimer}>
            <div className={styles.note_header}>
                <h3 className={styles.note_header_text}>Important Notes</h3>
                <img
                    className={`${styles.chevron} ${showDisclaimer && styles.rotated_chevron}`}
                    src={ChevronDownIcon}
                    alt="chevron"
                />
            </div>
            {showDisclaimer && (
                <div className={styles.notes}>
                    <p className={styles.note}>
                        Note: Essay grades are a heuristic and can fluctuate up and down slightly (e.g. from a B to a B+
                        or vice-versa) but generally not more than that. Think of this as how your essay roughly
                        compares to other successful essays!
                    </p>
                    <p className={styles.note}>
                        Note: An A+ or even an A is meant to be rare. When our platform gives that grade out, it means
                        it has satisfied all the core requirements that were present across thousands of essays that
                        actually worked. When that grade comes through, you know you have an extremely strong chance of
                        having close to a "perfect" essay.
                    </p>
                    <p className={styles.note}>
                        Note: Using Athena to receive AI-driven feedback on your college essays does not increase the
                        risk of being flagged by AI detection tools. We take data privacy very seriously and do not
                        share your work with any AI-detection tools. Your essays remain secure, private, and fully
                        yours.
                    </p>
                </div>
            )}
        </div>
    );
}

function PreviousSubmissionItem(props) {
    const { item, setSelectedItem, setShowPreviousSubmissionDisplay } = props;
    const handleClick = () => {
        setSelectedItem(item);
        setShowPreviousSubmissionDisplay(true);
    };

    return (
        <li className={styles.previous_submissions_list_item} onClick={handleClick}>
            <div className={styles.submission_info}>
                <span className={styles.submission_preview}>{item.essayPreview}</span>
                <span className={styles.submission_date}>{item.date}</span>
            </div>
            <img className={styles.chevron} src={ChevronRightIcon} alt="arrow" />
        </li>
    );
}

function PreviousSubmissionDisplay(props) {
    const { selectedItem, setShowPreviousSubmissionDisplay } = props;
    const [submission, setSubmission] = useState(null);

    // Fetch selected history item
    useEffect(() => {
        if (!selectedItem) return;

        getHistoryItem(selectedItem.historyId)
            .then((response) => {
                const rawSubmission = response.data;
                const formattedSubmission = {
                    essay: rawSubmission.initial_essay,
                    modelResponse: rawSubmission.model_response,
                    // date: new Date(rawSubmission.created_at).toLocaleString(), not currently used
                    athenaModel: rawSubmission.athena_model,
                    supplementalEssayCategory: rawSubmission.supplemental_essay_category,
                };
                setSubmission(formattedSubmission);
            })
            .catch((error) => toast.error(`Error fetching history item: ${error?.response?.data?.error}`));
    }, [selectedItem]);

    let athenaModel = "";
    switch (submission?.athenaModel) {
        case "flagship":
            athenaModel = "Flagship";
            break;
        case "pro":
            athenaModel = "Athena Pro";
            break;
        default:
            break;
    }

    return (
        <div className={styles.previous_submission_display_container}>
            <div className={styles.submission_header}>
                <div className={styles.submission_info}>
                    <div className={styles.submission_name}>{selectedItem?.essayPreview}</div>
                    <div className={styles.submission_timestamp}>{selectedItem?.date}</div>
                </div>
                <BaseButton className={`${styles.button}`} onClick={() => setShowPreviousSubmissionDisplay(false)}>
                    <img
                        className={styles.button_icon}
                        src={DoubleChevronRightIcon}
                        alt="button for closing athena sidebar"
                    />
                </BaseButton>
            </div>
            <div className={styles.submission_body}>
                <div className={styles.submission_essay_container}>
                    <div className={styles.submission_essay_title}>Your Submission</div>
                    <div>{submission?.essay}</div>
                </div>
                <div className={styles.submission_model_response_container}>
                    <div className={styles.submission_model_response_header}>
                        <div className={styles.submission_model_response_title}>Athena's feedback</div>
                        <div className={styles.submission_model_response_athena_model}>{athenaModel + " Model"}</div>
                    </div>
                    <div className={styles.model_response_container}>
                        <ReactMarkdown>{FormatModelResponse(submission?.modelResponse)}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function EssayReview(props) {
    const [showSubmission, setShowSubmission] = useState(false);
    const [showPreviousSubmissions, setShowPreviousSubmissions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const {
        essaySubmission,
        gradeErrorMessage,
        previousSubmissions,
        showPreviousSubmissionDisplay,
        setShowPreviousSubmissionDisplay,
    } = useApplicationContext();
    const errorMessageRef = useRef(null);

    // Slightly jank way to close the previous submission display when the sidebar tab is switched
    // TODO (Eugene): Fix entire state management flow
    useEffect(() => {
        setShowPreviousSubmissionDisplay(false);
    }, [setShowPreviousSubmissionDisplay]);

    useEffect(() => {
        if (gradeErrorMessage && errorMessageRef.current) {
            setTimeout(() => {
                errorMessageRef?.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500); // delay scroll to error to avoid interfering with sidebar opening animation
        }
    }, [gradeErrorMessage, errorMessageRef]); // We might not need this because it should already be in view?

    const handleShowSubmission = () => {
        if (!essaySubmission) return;
        setShowSubmission(!showSubmission);
    };

    return (
        <div className={styles.athena_essay_reviews}>
            <div className={styles.essay_submission}>
                <div className={styles.essay_submission_header} onClick={handleShowSubmission}>
                    <span className={styles.sidebar_section_title}>Your Submission</span>
                    <img
                        className={`${styles.chevron} ${showSubmission && styles.rotated_chevron}`}
                        src={ChevronDownIcon}
                        alt="chevron"
                    />
                </div>
                {/* TODO (Eugene): Make sure this essay submission can display markdown */}
                {showSubmission && essaySubmission && (
                    <div className={styles.essay_submission_content}>{essaySubmission}</div>
                )}
            </div>
            <EssayFeedback errorMessageRef={errorMessageRef} />
            <div className={styles.previous_submissions}>
                <div
                    className={styles.previous_submissions_header}
                    onClick={() => setShowPreviousSubmissions(!showPreviousSubmissions)}
                >
                    <span className={styles.sidebar_section_title}>Previous Submissions</span>
                    <img
                        className={`${styles.chevron} ${showPreviousSubmissions && styles.rotated_chevron}`}
                        src={ChevronUpIcon}
                        alt="chevron"
                    />
                </div>
                {showPreviousSubmissions &&
                    (previousSubmissions.length > 0 ? (
                        <ul className={styles.previous_submissions_list}>
                            {previousSubmissions.map((item, index) => (
                                <PreviousSubmissionItem
                                    key={index}
                                    item={item}
                                    setSelectedItem={setSelectedItem}
                                    setShowPreviousSubmissionDisplay={setShowPreviousSubmissionDisplay}
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.no_previous_submissions}>No previous submissions</div>
                    ))}
            </div>
            {showPreviousSubmissionDisplay && (
                <PreviousSubmissionDisplay
                    selectedItem={selectedItem}
                    setShowPreviousSubmissionDisplay={setShowPreviousSubmissionDisplay}
                />
            )}
        </div>
    );
}
