import LinkSubmissionScreen from "./gradeByLinkScreens/LinkSubmissionScreen";
import EssayFeedbackScreen from "./gradeByLinkScreens/EssayFeedbackScreen";
import styles from "../../styles/consultant/GradeByLinkPage.module.css";
import tools_styles from "../../styles/consultant/ConsultantToolsPage.module.css";
import { ReactComponent as ChevronLeftIcon } from "../../imgs/chevron-left-icon.svg";
import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export default function GradeByLinkPage() {
    const navigate = useNavigate();
    const [screen, setScreen] = useState("link_submission");
    const [pageState, setPageState] = useState({
        googleDocLink: "",
        googleDoc: {},
        selectedEssayType: null,
        selectedSchool: null,
        isFetchingEssay: false,
    });
    const pageStateSetters = {
        setGoogleDocLink: useCallback((link) => setPageState((prev) => ({ ...prev, googleDocLink: link })), []),
        setGoogleDoc: useCallback((doc) => setPageState((prev) => ({ ...prev, googleDoc: doc })), []),
        setSelectedEssayType: useCallback((type) => setPageState((prev) => ({ ...prev, selectedEssayType: type })), []),
        setSelectedSchool: useCallback((school) => setPageState((prev) => ({ ...prev, selectedSchool: school })), []),
        setIsFetchingEssay: useCallback(
            (isFetching) => setPageState((prev) => ({ ...prev, isFetchingEssay: isFetching })),
            []
        ),
    };

    const screenComponents = {
        link_submission: LinkSubmissionScreen,
        essay_feedback: EssayFeedbackScreen,
    };
    const ScreenComponent = screenComponents[screen];

    return (
        <div className={tools_styles.page_container}>
            <div className={tools_styles.page_header}>
                <div className={tools_styles.dashboard_return} onClick={() => navigate("/consultant/tools")}>
                    <ChevronLeftIcon className={tools_styles.dashboard_return_icon} />
                    Consultant Tools
                </div>
                <h1 className={tools_styles.title}>Grade Your Student's Essay Straight From Google Docs</h1>
            </div>
            <div className={tools_styles.page_content}>
                <ScreenComponent {...pageState} {...pageStateSetters} setScreen={setScreen} />
            </div>
        </div>
    );
}
