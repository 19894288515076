import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { generateNiceTimestamp } from "../main/utils/utils";
import { Document, HeadingLevel, Packer, Paragraph, TextRun, SectionType } from "docx";
import { saveAs } from "file-saver";
import { parseDocument, DomUtils } from "htmlparser2";

const collectChildNodes = (node) => {
    if (!node) return [];

    if (node.getAttribute("data-leaf-node")) {
        return [node];
    }

    const nodeDepth = node.getAttribute("data-node-depth");
    if (nodeDepth) {
        return _collectChildNodesWithDepth(node, parseInt(nodeDepth));
    }

    return Array.from(node.childNodes)
        .map((child) => collectChildNodes(child))
        .flat();
};

const _collectChildNodesWithDepth = (node, depth = 0) => {
    if (!node) return [];

    if (node.nodeType !== Node.ELEMENT_NODE) {
        return [];
    }

    if (
        depth <= 0 ||
        node.getAttribute("data-leaf-node") ||
        node.nodeType === Node.COMMENT_NODE ||
        node.localName === "ul"
    ) {
        return [node];
    }
    const newDepth = node.getAttribute("data-node-depth") ? parseInt(node.getAttribute("data-node-depth")) : depth - 1;
    const childNodes = Array.from(node.childNodes)
        .map((child) => _collectChildNodesWithDepth(child, newDepth))
        .flat();
    return childNodes.length > 0 ? childNodes : [node];
};

export const exportToPDF = async (element) => {
    if (!element) {
        toast.error("No element to export");
        return;
    }

    try {
        const pdf = new jsPDF("p", "pt", "letter", true);
        const pageHeight = pdf.internal.pageSize.height;
        const horizontalPadding = 72;
        const verticalPadding = 48;
        const componentGap = 12;
        const pageHeightWithPadding = pageHeight - verticalPadding * 2;

        let currentY = verticalPadding;
        const nodes = collectChildNodes(element);
        for (const node of nodes) {
            const canvas = await html2canvas(node, {
                scale: 4,
                quality: 4,
                windowWidth: node.scrollWidth,
                windowHeight: node.scrollHeight,
            });
            const imgData = canvas.toDataURL("image/png");

            if (currentY + node.scrollHeight > pageHeightWithPadding) {
                pdf.addPage(); // Add a new page
                currentY = verticalPadding; // Reset currentY for the new page
            }

            pdf.addImage(imgData, "PNG", horizontalPadding, currentY, node.scrollWidth, node.scrollHeight);
            currentY += node.scrollHeight + componentGap;
        }

        const timestamp = generateNiceTimestamp();
        pdf.save(`feedback_${timestamp}.pdf`);
    } catch (error) {
        toast.error(`Error generating PDF: ${error}`);
    }
};

export const exportToWord = async (element) => {
    // Merges two objects, with the extra object taking precedence for nested objects
    function mergeOptions(base, extra) {
        const result = { ...base };
        for (const [key, value] of Object.entries(extra)) {
            if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                // Both base and extra have this key
                const baseValue = result[key];
                if (typeof baseValue === "object" && baseValue !== null && !Array.isArray(baseValue)) {
                    // Merge objects
                    result[key] = mergeOptions(baseValue, value);
                } else {
                    // Replace if baseValue is not an object
                    result[key] = { ...value };
                }
            } else {
                // Overwrite non-object value
                result[key] = value;
            }
        }
        return result;
    }

    // Always returns a list of runs
    const domNodeToRuns = (node, runOptions = {}) => {
        if (node.type === "text") {
            const trimmedText = node.data.trim();
            return trimmedText
                ? [
                      new TextRun({ text: trimmedText, size: "12pt", ...runOptions, font: "Times New Roman" }),
                      new TextRun(" "),
                  ]
                : [];
        }

        if (node.type === "tag") {
            const children = node.children || [];

            switch (node.name) {
                case "strong":
                    return children.flatMap((child) => domNodeToRuns(child, { ...runOptions, bold: true }));
                case "b":
                    return children.flatMap((child) => domNodeToRuns(child, { ...runOptions, bold: true }));
                case "em":
                    return children.flatMap((child) => domNodeToRuns(child, { ...runOptions, italics: true }));
                case "i":
                    return children.flatMap((child) => domNodeToRuns(child, { ...runOptions, italics: true }));
                default:
                    return children.flatMap((child) => domNodeToRuns(child, runOptions));
            }
        }

        return [];
    };

    // Returns a list of paragraphs
    const domNodeToParagraphs = (node, paragraphOptions = {}, runOptions = {}, exportAsRun = false) => {
        const h1Options = mergeOptions({ heading: HeadingLevel.HEADING_1, spacing: { after: 100 } }, paragraphOptions);
        const h2Options = mergeOptions({ heading: HeadingLevel.HEADING_2, spacing: { after: 200 } }, paragraphOptions);
        const h3Options = mergeOptions({ heading: HeadingLevel.HEADING_3, spacing: { after: 100 } }, paragraphOptions);
        let children;
        let currentRunOptions = { ...runOptions };
        let currentParagraphOptions = {
            ...paragraphOptions,
            spacing: { line: 240 * 1.25, lineRule: "exact" },
        };

        // Manual style overrides
        if (node.attribs?.["data-docx-i"]) {
            currentRunOptions = mergeOptions(currentRunOptions, { italics: true });
        }
        if (node.attribs?.["data-docx-break"]) {
            currentRunOptions = mergeOptions(currentRunOptions, { break: parseInt(node.attribs["data-docx-break"]) });
        }
        if (node.attribs?.["data-docx-spacing-after"]) {
            currentParagraphOptions = mergeOptions(currentParagraphOptions, {
                spacing: { after: parseInt(node.attribs["data-docx-spacing-after"]) },
            });
        }

        // Force bulleted list if div has data-docx-ul. Used for docx processing of google doc comments
        if (node.attribs?.["data-docx-ul"]) {
            currentParagraphOptions = mergeOptions(currentParagraphOptions, { bullet: { level: 0 } });
        }

        try {
            if (node.type === "text") {
                const trimmedText = node.data.trim();
                children = [new TextRun({ text: trimmedText, size: "11pt", font: "Times New Roman" })];

                return trimmedText
                    ? exportAsRun
                        ? children
                        : [new Paragraph({ children, ...currentParagraphOptions })]
                    : [];
            }
            if (node.type === "tag") {
                switch (node.name) {
                    case "p":
                        children = domNodeToRuns(node, currentRunOptions);
                        return exportAsRun
                            ? children
                            : [
                                  new Paragraph({
                                      children,
                                      ...currentParagraphOptions,
                                  }),
                              ];
                    case "h1":
                        children = [
                            new TextRun({
                                text: DomUtils.textContent(node),
                                size: "18pt",
                                break: 1,
                            }),
                        ];
                        return exportAsRun
                            ? children
                            : [new Paragraph({ children, ...mergeOptions(h1Options, currentParagraphOptions) })];
                    case "h2":
                        children = [
                            new TextRun({
                                text: DomUtils.textContent(node),
                                size: "16pt",
                                break: 1,
                            }),
                        ];
                        return exportAsRun
                            ? children
                            : [new Paragraph({ children, ...mergeOptions(h2Options, currentParagraphOptions) })];
                    case "h3":
                        children = [
                            new TextRun({
                                text: DomUtils.textContent(node),
                                size: "14pt",
                                break: 1,
                            }),
                        ];
                        return exportAsRun
                            ? children
                            : [new Paragraph({ children, ...mergeOptions(h3Options, currentParagraphOptions) })];
                    case "ul":
                        return (node.children || []).flatMap((li) => {
                            if (li.type === "tag" && li.name === "li") {
                                children = domNodeToRuns(li, currentRunOptions);
                                return exportAsRun
                                    ? children
                                    : [
                                          new Paragraph({
                                              bullet: { level: 0 },
                                              children,
                                              ...mergeOptions(
                                                  {
                                                      spacing: { after: 100 },
                                                  },
                                                  currentParagraphOptions
                                              ),
                                          }),
                                      ];
                            }
                            return [];
                        });
                    case "div":
                        // List items should be created as a single paragraph
                        if (node.attribs?.["data-docx-li"]) {
                            const liChildrenRuns = (node.children || []).flatMap((el) =>
                                domNodeToParagraphs(el, currentParagraphOptions, currentRunOptions, true)
                            );
                            return exportAsRun
                                ? liChildrenRuns
                                : [
                                      new Paragraph({
                                          children: liChildrenRuns,
                                          ...currentParagraphOptions,
                                      }),
                                  ];
                        }

                        // Add spacing before and after the first and last child of a div
                        const divChildrenParagraphs = (node.children || []).flatMap((e, index) => {
                            if (index === 0) {
                                currentParagraphOptions = mergeOptions(currentParagraphOptions, {
                                    spacing: { before: 100 },
                                });
                            }
                            if (index === node.children.length - 1) {
                                currentParagraphOptions = mergeOptions(currentParagraphOptions, {
                                    spacing: { after: 100 },
                                });
                            }
                            return domNodeToParagraphs(e, currentParagraphOptions);
                        });

                        if (divChildrenParagraphs.length === 0) {
                            return [];
                        }
                        return divChildrenParagraphs;
                    default:
                        // Recursively process children if not recognized as a block element
                        return (node.children || []).flatMap(domNodeToParagraphs);
                }
            }
        } catch (error) {
            console.error("Error parsing HTML:", error);
        }

        // Non-tag nodes might be text at top-level (unlikely if well-structured)
        return [];
    };

    try {
        // Step 1: Parse the HTML into a DOM node using htmlparser2
        const parsedElement = parseDocument(element.innerHTML);

        // Step 2: Convert the DOM node into a list of Paragraph elements for docx
        const children = parsedElement.children.flatMap(domNodeToParagraphs).filter(Boolean);

        // Step 3: Create a Word document using docx
        const doc = new Document({
            properties: {
                type: SectionType.CONTINUOUS,
            },
            sections: [
                {
                    children: children,
                },
            ],
        });

        // Step 4: Save the document as a Word file
        Packer.toBlob(doc).then((blob) => {
            const timestamp = generateNiceTimestamp();
            saveAs(blob, `feedback_${timestamp}.docx`);
        });
    } catch (error) {
        toast.error(`Error generating Word Doc: ${error}`);
    }
};
