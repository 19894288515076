// Banner.jsx
import styles from "../../styles/banner/Banner.module.css"; // Import CSS module or stylesheet
import { useState } from "react";
import BaseButton from "../base/BaseButton";
import { ReactComponent as CloseIcon } from "../../imgs/close-icon.svg";

const Banner = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    return (
        <div className={`${styles.banner} ${!isBannerVisible && styles.hidden}`}>
            <BaseButton className={styles.close_button} onClick={() => setIsBannerVisible(false)}>
                <CloseIcon />
            </BaseButton>
            Our grading features are temporarily unavailable due to an issue with our service provider. We apologize for
            the inconvenience.{" "}
        </div>
    );
};

export default Banner;
