import { createRef, useCallback, useEffect, useState } from "react";
import {
    assignConsultantCurrentStudent,
    checkAuthentication,
    checkPasswordResetLink,
    cleverLogin,
    createFeatureFlag,
    createReferralCode,
    createUserOverride,
    deactivateAccount,
    deleteAccountLinkingRequest,
    deleteUserOverride,
    fetchAdminDashboard,
    fetchAdminDashboardLast50Users,
    fetchConsultantCurrentStudent,
    fetchConsultantOrganizationData,
    fetchConsultantRegisteredStudents,
    fetchFeatureFlags,
    fetchFeatureFlag,
    fetchIPGeolocation,
    fetchReferralCode,
    fetchReferralCodeUsage,
    fetchServiceStatus,
    getUserProfile,
    gradeGoogleDoc,
    gradeGoogleDocLink,
    linkAccount,
    processAccountSetup,
    refreshServiceStatus,
    registerConsultantStudent,
    sendAccountLinkingRequest,
    sendPasswordResetEmail,
    updateFeatureFlag,
    unlinkAccount,
    userGoogleLogin,
    userGoogleSignupViaAccessToken,
    userLogin,
    userLogOut,
    userResetPassword,
    userSignup,
} from "../../api/apiCalls";
import useUserContext from "./useUserContext";
import { AuthContext } from "./AuthContext";
import { Logtail } from "@logtail/browser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

const MARKETING_SITE_URL = process.env.REACT_APP_MARKETING_SITE_URL;

const canSeeConsultantUI = (user) => {
    if (!user) {
        return false;
    }
    return (
        user.special_account_type === "consultant" || // Temporarily hide consultant UI from eugene and ajay
        // user.email === "eshao573@gmail.com" ||
        // user.email === "ajnatarajan@gmail.com" ||
        user.email === "support@athenaco.ai"
    );
};

export default function AuthProvider({ children }) {
    const {
        userProfile,
        setUserProfile,
        frontendActiveProfile,
        setFrontendActiveProfile,
        isLoggedIn,
        setIsLoggedIn,
        setIsIEC,
        isUserContextLoading,
        setIsUserContextLoading,
    } = useUserContext();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Consultant UI
    const [registeredStudents, setRegisteredStudents] = useState([]);
    const [organizationData, setOrganizationData] = useState({});
    const [consultantCurrentStudent, setConsultantCurrentStudent] = useState({});

    const reloadUserContext = useCallback(() => {
        setIsUserContextLoading(true);
    }, [setIsUserContextLoading]);

    async function handleGoogleLogin(credentialResponse, navigateOnLoginCompletion) {
        try {
            await userGoogleLogin(credentialResponse.credential);
            reloadUserContext();
            navigate(navigateOnLoginCompletion);
        } catch (error) {
            toast.error(`${error?.response?.data?.error}`);
            navigate(`/signup?${searchParams.toString()}`);
        }
    }

    async function handleUserLogin(formData, navigateOnLoginCompletion) {
        try {
            await userLogin(formData);
            reloadUserContext();
            navigate(navigateOnLoginCompletion);
        } catch (error) {
            // TODO (Eugene): There's definitely a better way to handle displaying errors...right?
            throw error;
        }
    }

    async function handleUserLogout(logoutRedirectUrl = MARKETING_SITE_URL) {
        try {
            await userLogOut();
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }

        // No need to update state, since we always refetch the user context on page load
        window.location.replace(logoutRedirectUrl);
    }

    async function handleGoogleSignupViaAccessToken(tokenResponse, navigateOnSignupCompletion, referralSource) {
        let country = "";
        try {
            const response = await fetchIPGeolocation();
            country = response?.data?.country;
        } catch (error) {
            logtail.info(`Error fetching IP Geolocation data`, {
                additionalInfo: {
                    message: error?.response?.data?.error,
                },
            });
        }

        const formattedData = {
            access_token: tokenResponse.access_token,
            country: country,
            referral_source: referralSource,
        };
        userGoogleSignupViaAccessToken(formattedData)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                setIsIEC(user?.special_account_type === "consultant");
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                logtail.warn(`Error during google user signup`, {
                    additionalInfo: {
                        message: error?.response?.data?.error,
                        tokenErrorDescription: tokenResponse?.error_description ?? "",
                    },
                });
                toast.error(error?.response?.data?.error);
            });
    }

    function handlePasswordResetLinkCheck(userId, token) {
        return checkPasswordResetLink(userId, token);
    }

    function handleResetPassword(formData) {
        return userResetPassword(formData)
            .then((_) => {
                // TODO: Replace with toast notifications
                alert("Password reset successfully.");
                navigate("/login");
            })
            .catch((error) => {
                throw error;
            });
    }

    function handleSendPasswordResetEmail(formData) {
        return sendPasswordResetEmail(formData)
            .then((_) => {})
            .catch((error) => {
                throw error;
            });
    }

    async function handleUserSignup(formData, navigateOnSignupCompletion) {
        let country = "";
        try {
            const response = await fetchIPGeolocation();
            country = response?.data?.country;
        } catch (error) {
            logtail.info(`Error fetching IP Geolocation data`, {
                additionalInfo: {
                    message: error?.response?.data?.error,
                },
            });
        }

        formData["country"] = country;
        return userSignup(formData)
            .then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                setIsIEC(user?.special_account_type === "consultant");
                navigate(navigateOnSignupCompletion);
            })
            .catch((error) => {
                logtail.warn(`Error during user signup ${formData?.email}`, {
                    additionalInfo: {
                        message: error?.response?.data?.error,
                    },
                });
                throw error;
            });
    }

    const handleDeactivateAccount = useCallback(() => {
        deactivateAccount()
            .then((_) => {
                toast.info("Your account has been deactivated!");
                setTimeout(() => {
                    handleUserLogout();
                }, 3000);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
    }, [handleUserLogout]);

    const _handleFetchConsultantUICurrentStudent = useCallback(async () => {
        try {
            const fetchConsultantCurrentStudentResponse = await fetchConsultantCurrentStudent();
            const studentProfile = fetchConsultantCurrentStudentResponse.data.student;
            if (studentProfile.id) {
                setFrontendActiveProfile(studentProfile);
                setConsultantCurrentStudent(studentProfile);
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    }, [setFrontendActiveProfile, setConsultantCurrentStudent]);

    const _handleGetUserProfile = useCallback(async () => {
        try {
            const getUserProfileResponse = await getUserProfile();
            const user = getUserProfileResponse.data.user;
            setUserProfile(user);
            setFrontendActiveProfile(user); // default to consultant's profile
            setIsIEC(user?.special_account_type === "consultant");

            if (canSeeConsultantUI(user)) {
                await _handleFetchConsultantUICurrentStudent();
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    }, [setFrontendActiveProfile, setUserProfile, setIsIEC, _handleFetchConsultantUICurrentStudent]);

    const _handleAuthenticationCheck = useCallback(async () => {
        try {
            const checkAuthenticationResponse = await checkAuthentication();
            const loggedIn = checkAuthenticationResponse.data.is_authenticated;
            setIsLoggedIn(loggedIn);
            setIsIEC(false);

            if (loggedIn) {
                await _handleGetUserProfile();
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
        } finally {
            setIsUserContextLoading(false);
        }
    }, [setFrontendActiveProfile, setIsLoggedIn, setUserProfile, setIsIEC, setIsUserContextLoading]);

    function handleAccountSetup(data) {
        processAccountSetup(data)
            .then((_) => navigate("/"))
            .catch((_) => {});
    }

    function handleAccountLinkingRequest(data) {
        // We can do a simple return sendAccountLinkingRequest(data) here
        return sendAccountLinkingRequest(data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    const handleCleverLogin = useCallback(
        (formData) =>
            cleverLogin(formData).then((response) => {
                const user = response.data.user;
                setUserProfile(user);
                setFrontendActiveProfile(user);
                setIsLoggedIn(true);
                navigate("/");
            }),
        [navigate, setFrontendActiveProfile, setIsLoggedIn, setUserProfile]
    );

    // ==============================
    // User Settings Actions
    // ==============================

    async function updateLoginToEmail(formData) {
        return sendPasswordResetEmail(formData)
            .then((response) => `An email has been sent to ${formData.email}`)
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            });
    }

    const handleCreateReferralCode = useCallback((formData) => createReferralCode(formData), []);
    const handleFetchReferralCode = useCallback(async () => fetchReferralCode(), []);
    const handleFetchReferralCodeUsage = useCallback(
        async (referralCode, promotion = "") => fetchReferralCodeUsage(referralCode, promotion),
        []
    );

    // DEPRECATED
    function handleAccountUnlinkingRequest(formData) {
        return unlinkAccount(formData)
            .then((_) => console.log("unlinking request sent successfully!"))
            .catch((_) => console.error("account unlinking failed"));
    }

    function handleConfirmIncomingLinkingRequest(formData) {
        return linkAccount(formData)
            .then((_) => console.log("linking account confirmed successfully!"))
            .catch((_) => console.error("account linking failed"));
    }

    function handleDeleteIncomingLinkingRequest(formData) {
        return deleteAccountLinkingRequest(formData)
            .then((_) => console.log("linking account deleted successfully!"))
            .catch((_) => console.error("account linking deletion failed"));
    }

    // Admin dashboard stuff
    const handleFetchAdminDashboard = useCallback(() => fetchAdminDashboard(), []);
    const handleFetchAdminDashboardLast50Users = useCallback((page) => fetchAdminDashboardLast50Users(page), []);
    const handleFetchFeatureFlags = useCallback(() => fetchFeatureFlags(), []);
    const handleFetchFeatureFlag = useCallback((featureFlagName) => fetchFeatureFlag(featureFlagName), []);
    const handleUpdateFeatureFlag = useCallback((flagName, formData) => updateFeatureFlag(flagName, formData), []);
    const handleCreateFeatureFlag = useCallback((formData) => createFeatureFlag(formData), []);
    const handleCreateUserOverride = useCallback((flagName, formData) => createUserOverride(flagName, formData), []);
    const handleDeleteUserOverride = useCallback((flagName, email) => deleteUserOverride(flagName, email), []);
    const handleFetchServiceStatus = useCallback((serviceName) => fetchServiceStatus(serviceName), []);
    const handleRefreshServiceStatus = useCallback((formData) => refreshServiceStatus(formData), []);

    const handleFetchConsultantOrganizationData = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }
        return fetchConsultantOrganizationData()
            .then((response) => {
                setOrganizationData(response.data.organization);
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);
    const handleFetchConsultantRegisteredStudents = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }

        // organizationData is usually not loaded yet, so this was the cleanest option I could find
        let is_admin_sees_all_students = null;

        await fetchConsultantOrganizationData()
            .then((response) => {
                is_admin_sees_all_students = response.data.organization.is_admin_sees_all_students;
            })
            .catch((error) => toast.error(error?.response?.data?.error));

        return fetchConsultantRegisteredStudents(is_admin_sees_all_students)
            .then((response) => setRegisteredStudents(response.data.students))
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);

    const handleRegisterConsultantStudent = useCallback((formData) => registerConsultantStudent(formData), []);
    const handleAssignConsultantCurrentStudent = useCallback(
        async (student) => {
            const formattedData = {
                student_id: student.id,
            };
            assignConsultantCurrentStudent(formattedData)
                .then((response) => {
                    setConsultantCurrentStudent(response.data.student);
                    navigate("/");
                })
                .catch((error) => toast.error(error?.response?.data?.error));
            return;
        },
        [navigate]
    );
    const handleFetchConsultantCurrentStudent = useCallback(async () => {
        if (!isLoggedIn || !canSeeConsultantUI(userProfile)) {
            return;
        }
        return fetchConsultantCurrentStudent()
            .then((response) => setConsultantCurrentStudent(response.data.student))
            .catch((error) => toast.error(error?.response?.data?.error));
    }, [isLoggedIn, userProfile]);

    const handleGradeGoogleDocLink = useCallback((formData) => gradeGoogleDocLink(formData), []);
    const handleGradeGoogleDoc = useCallback((formData) => gradeGoogleDoc(formData), []);

    // ==============================
    // Hooks and Effects
    // ==============================

    // Load IEC information on page load
    useEffect(() => {
        handleFetchConsultantOrganizationData();
        handleFetchConsultantRegisteredStudents();
        handleFetchConsultantCurrentStudent();
    }, [
        handleFetchConsultantCurrentStudent,
        handleFetchConsultantOrganizationData,
        handleFetchConsultantRegisteredStudents,
    ]);

    // Update frontendActiveProfile whenever userProfile changes
    useEffect(() => {
        if (isLoggedIn && canSeeConsultantUI(userProfile)) {
            consultantCurrentStudent.id
                ? setFrontendActiveProfile(consultantCurrentStudent)
                : setFrontendActiveProfile(userProfile); // default profile to consultant's profile if consultant has no students
        }
    }, [isLoggedIn, userProfile, consultantCurrentStudent, setFrontendActiveProfile]);

    // Reload authentication information whenever isUserContextLoading is true
    useEffect(() => {
        if (!isUserContextLoading) return;

        async function authCheck() {
            await _handleAuthenticationCheck();
        }

        authCheck();
    }, [isUserContextLoading, _handleAuthenticationCheck]);

    return (
        <AuthContext.Provider
            value={{
                // Authentication
                reloadUserContext,

                // Other functions
                handleAccountLinkingRequest,
                handleAccountUnlinkingRequest,
                handleConfirmIncomingLinkingRequest,
                handleDeleteIncomingLinkingRequest,
                handleUserLogin,
                handleUserLogout,
                handleGoogleLogin,
                handleGoogleSignupViaAccessToken,
                handlePasswordResetLinkCheck,
                handleResetPassword,
                handleSendPasswordResetEmail,
                handleUserSignup,
                handleAccountSetup,
                handleCleverLogin,
                handleDeactivateAccount,

                // Admin Dashboard
                handleCreateFeatureFlag,
                handleFetchAdminDashboard,
                handleFetchAdminDashboardLast50Users,
                handleFetchFeatureFlags,
                handleFetchFeatureFlag,
                handleUpdateFeatureFlag,
                handleCreateUserOverride,
                handleDeleteUserOverride,
                handleFetchServiceStatus,
                handleRefreshServiceStatus,

                // Consultant UI
                handleFetchConsultantCurrentStudent,
                handleFetchConsultantOrganizationData,
                handleFetchConsultantRegisteredStudents,
                handleRegisterConsultantStudent,
                handleAssignConsultantCurrentStudent,
                consultantCurrentStudent,
                organizationData,
                registeredStudents,
                handleGradeGoogleDocLink,
                handleGradeGoogleDoc,
                // User settings page
                handleCreateReferralCode,
                handleFetchReferralCode,
                handleFetchReferralCodeUsage,
                updateLoginToEmail,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
