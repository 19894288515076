import styles from "../../styles/pricing/PricingPage.module.css";
import useUserContext from "../auth/useUserContext";
import BaseButton from "../base/BaseButton";
import checkCircle from "../../imgs/check_circle_green.png";
import { createBillingPortalSession, createCheckoutSession, fulfillCheckoutSession } from "../../api/apiCalls";

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Logtail } from "@logtail/browser";

import { toast } from "react-toastify";

const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_ATHENA_FRONTEND_SOURCE_TOKEN);

export default function PricingPage(props) {
    const { userProfile, isIEC } = useUserContext();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("success");
    const sessionId = searchParams.get("session_id");
    const canceled = searchParams.get("canceled");

    useEffect(() => {
        if (process.env.REACT_APP_IS_LOCAL_ENVIRONMENT === "False") {
            if (canceled) {
                logtail.info(`Payment canceled ${userProfile?.id}`, {
                    additionalInfo: {
                        userId: userProfile?.id,
                    },
                });
            } else if (success) {
                // TODO (Eugene): Move checkout completion page to a separate page
                const data = { session_id: sessionId };
                fulfillCheckoutSession(data)
                    .then((response) => {
                        logtail.info(`Payment successful ${userProfile?.id}`, {
                            additionalInfo: {
                                success: success,
                                sessionId: sessionId,
                                userId: userProfile?.id,
                            },
                        });
                    })
                    .catch((error) => toast.error(error?.response?.data?.error));
            }
            logtail.flush();
        }
    }, []);

    if (isIEC) {
        return (
            <div className={styles.empty_page}>
                This page is not available for IECs. If you need help, please reach out to{" "}
                <a className={styles.mail_link} href="mailto:support@athenaco.ai">
                    support@athenaco.ai
                </a>
            </div>
        );
    }

    const pricing_table = (
        <>
            <div className={styles.pricing_page_container}>
                <div className={styles.pricing_title}>Shape Your Success Story</div>
                <PricingTable
                    userId={userProfile.id}
                    membershipPlan={userProfile.membership_plan}
                    shouldOpenInNewTab={props.shouldOpenInNewTab}
                />
            </div>
        </>
    );
    return pricing_table;
}

/**
 * Helper function to determine the action button for each pricing option
 *
 * "Manage Plan" button goes to the billing portal (for current plan)
 * "Get Started" button goes to the checkout session (for new plan)
 */
function actionButtonHelper(userId, currentPlan, newPlan, color, shouldOpenInNewTab) {
    const handleManagePlanRedirect = async () => {
        const data = { user_id: userId };
        try {
            const response = await createBillingPortalSession(data);
            if (shouldOpenInNewTab) {
                const newWindow = window.open(response.data.url, "_blank");
                // This JS is the equivalent of adding the "noopener" attribute. _blank is a security risk without this.
                if (newWindow) {
                    newWindow.opener = null;
                }
            } else {
                window.location.href = response.data.url;
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
        }
    };
    if (currentPlan === newPlan) {
        return (
            <BaseButton
                className={styles.pricing_option_current_plan}
                style={{ border: `1px solid ${color}` }}
                onClick={() => handleManagePlanRedirect()}
            >
                Manage Plan
            </BaseButton>
        );
    }

    const handleCheckoutSession = async (newPlan) => {
        if (newPlan === "free") {
            const data = { user_id: userId };
            try {
                const response = await createBillingPortalSession(data);
                if (shouldOpenInNewTab) {
                    const newWindow = window.open(response.data.url, "_blank");
                    // This JS is the equivalent of adding the "noopener" attribute. _blank is a security risk without this.
                    if (newWindow) {
                        newWindow.opener = null;
                    }
                } else {
                    window.location.href = response.data.url;
                }
            } catch (error) {
                toast.error(error?.response?.data?.error);
            }
            return;
        }

        const getNewPlanId = (newPlan) => {
            if (newPlan === "pro") {
                return process.env.REACT_APP_STRIPE_ATHENA_PRO_MONTHLY_PRICE_ID;
            } else if (newPlan === "lite") {
                return process.env.REACT_APP_STRIPE_ATHENA_LITE_MONTHLY_PRICE_ID;
            } else if (newPlan === "starter") {
                return process.env.REACT_APP_STRIPE_ATHENA_STARTER_MONTHLY_PRICE_ID;
            } else {
                logtail.error("Invalid plan selected", {
                    additionalInfo: {
                        newPlan: newPlan,
                    },
                });
                toast.error(`Invalid plan selected: ${newPlan}. Please contact support for help.`);
            }
        };
        const newPlanId = getNewPlanId(newPlan);
        const data = {
            price_id: newPlanId,
            user_id: userId,
        };

        try {
            const response = await createCheckoutSession(data);
            if (shouldOpenInNewTab) {
                const newWindow = window.open(response.data.checkout_url, "_blank");
                // This JS is the equivalent of adding the "noopener" attribute. _blank is a security risk without this.
                if (newWindow) {
                    newWindow.opener = null;
                }
            } else {
                window.location.href = response.data.checkout_url;
            }
        } catch (error) {
            logtail.error("Error creating checkout session", {
                additionalInfo: {
                    message: error?.response?.data?.error,
                },
            });
            toast.error(error?.response?.data?.error);
        }
        logtail.flush();
    };

    return (
        <BaseButton
            className={styles.pricing_option_button}
            style={{ background: color }}
            onClick={() => handleCheckoutSession(newPlan)}
        >
            Get Started
        </BaseButton>
    );
}

function PricingTable(props) {
    const { userId, membershipPlan } = props;

    const starterColor = "#B6A0CA";
    const liteColor = "#0085FF";
    const proColor = "#FF7971";

    const athenaStarterPricingData = {
        title: (
            <>
                Athena <span style={{ color: starterColor }}>Starter</span>
            </>
        ),
        bestForText: "For everyone",
        color: starterColor,
        price: 14.99,
        showBadge: false,
        features: [
            <span>
                <span className={styles.text_bold}>Unlimited</span> AI suggestions (brainstorm, progress check)
            </span>,
            <span>
                <span className={styles.text_bold}>Unlimited</span> colleges
            </span>,
            <span>1 use of AI grading, limited to your Common App essay</span>,
        ],
        actionButton: actionButtonHelper(userId, membershipPlan, "starter", starterColor, props.shouldOpenInNewTab),
    };
    const athenaLitePricingData = {
        title: (
            <>
                Athena <span style={{ color: liteColor }}>Lite</span>
            </>
        ),
        bestForText: "Applying to top 50 schools?",
        color: liteColor,
        price: 39.99,
        showBadge: true,
        badge: "Most Popular",
        features: [
            <span>
                <span className={styles.text_bold}>Unlimited</span> AI suggestions
            </span>,
            <span>
                <span className={styles.text_bold}>Unlimited</span> colleges
            </span>,
            <span>
                <span className={styles.text_bold}>Unlimited</span> AI grading
            </span>,
            <span>AI grading unlocked for all essays</span>,
        ],
        actionButton: actionButtonHelper(userId, membershipPlan, "lite", liteColor, props.shouldOpenInNewTab),
    };
    const athenaProPricingData = {
        title: (
            <>
                Athena <span style={{ color: proColor }}>Pro</span>
            </>
        ),
        bestForText: "Targeting the Ivy League?",
        price: 89.99,
        color: proColor,
        showBadge: false,
        features: [
            <span>
                <span className={styles.text_bold}>Everything</span> in Athena Lite
            </span>,
            <span>
                <span className={styles.text_bold}>Ivy League</span> tailored insights
            </span>,
            <span>
                Specialized AI for every single <span className={styles.text_bold}>Top 15 university</span>
            </span>,
        ],
        actionButton: actionButtonHelper(userId, membershipPlan, "pro", proColor, props.shouldOpenInNewTab),
    };
    return (
        <div className={styles.pricing_option_container}>
            <div className={styles.pricing_option_subcontainer}>
                <PricingOption {...athenaStarterPricingData} />
                <PricingOption {...athenaLitePricingData} />
            </div>
            <div className={styles.pricing_option_subcontainer}>
                <PricingOption {...athenaProPricingData} />
            </div>
        </div>
    );
}

function PricingOption(props) {
    const { title, bestForText, color, price, showBadge, features, actionButton } = props;
    const featuresList = features.map((feature, index) => {
        return (
            <div key={index} className={styles.pricing_option_feature}>
                <img src={checkCircle} alt="Princeton" key={index} />
                <span className={styles.pricing_option_feature_text}>{feature}</span>
            </div>
        );
    });
    const roundPrice = Math.trunc(price).toString().slice(0, 2);
    const subPrice = price.toFixed(2).slice(-2);
    const showSubPrice = subPrice !== "00";

    const borderThickness = showBadge ? "3px" : "1px";

    return (
        <div
            className={`${styles.pricing_option_card} ${showBadge && styles.pricing_option_card_extra_dimensions}`}
            style={{ border: `${borderThickness} solid ${color}` }}
        >
            <div className={styles.pricing_option_best_for_banner} style={{ backgroundColor: color }}>
                <div className={styles.pricing_option_best_for_text}>{bestForText}</div>
            </div>
            <div
                className={`${styles.pricing_option_top_section} ${
                    showBadge && styles.pricing_option_top_section_extra_height
                }`}
            >
                <div className={styles.pricing_option_title_container}>
                    <h3 className={styles.pricing_option_title}>{title}</h3>
                    {showBadge ? <div className={styles.pricing_option_badge}>{props.badge}</div> : null}
                </div>
                <div className={styles.pricing_option_price_container}>
                    <div>
                        <span className={styles.pricing_option_dollar_sign}>$</span>
                        <span className={styles.pricing_option_price}>{roundPrice}</span>
                        <span className={styles.pricing_option_subprice}>{showSubPrice ? "." + subPrice : null}</span>
                    </div>
                    <div className={styles.pricing_option_plan_duration}>per month</div>
                </div>
            </div>
            <div className={styles.horizontal_divider} />
            <div className={styles.pricing_option_features_container}>{featuresList}</div>
            <div className={styles.pricing_option_button_container}>{actionButton}</div>
        </div>
    );
}
