import useAuthContext from "../auth/useAuthContext";
import { formatTimestamp } from "../../api/utils";
import { Card } from "./AdminDashboard";
import styles from "../../styles/admin/AdminDashboard.module.css";
import BaseButton from "../base/BaseButton";
import { toast } from "react-toastify";

function ServiceStatusBody({ serviceStatusData, setRefreshKey }) {
    const { handleRefreshServiceStatus } = useAuthContext();
    if (!serviceStatusData) {
        return <div>No service status data</div>;
    }

    const handleRefreshServiceStatusClick = () => {
        const formData = {
            service_name: serviceStatusData.service_name,
        };
        handleRefreshServiceStatus(formData)
            .then((_) => {
                toast.success("Service status refreshed successfully");
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((error) => toast.error(error?.response?.data?.error || error?.response?.data?.detail));
    };

    return (
        <div>
            <p className={styles.list_title}>{serviceStatusData.service_name}:</p>
            <ul>
                <li>Is Down: {serviceStatusData.is_down ? "True" : "False"}</li>
                <li>Message: {serviceStatusData.message}</li>
                <li>Last Checked: {formatTimestamp(serviceStatusData.last_checked)}</li>
                <li>
                    Estimated Resolution:{" "}
                    {serviceStatusData.estimated_resolution
                        ? formatTimestamp(serviceStatusData.estimated_resolution)
                        : "N/A"}
                </li>
            </ul>
            <BaseButton className={styles.refresh_button} onClick={handleRefreshServiceStatusClick}>
                Refresh
            </BaseButton>
        </div>
    );
}

export default function ServiceStatusPage({ serviceStatusData, setRefreshKey }) {
    return (
        <div className={styles.page_container}>
            <Card
                title={"Service Status"}
                body={<ServiceStatusBody serviceStatusData={serviceStatusData} setRefreshKey={setRefreshKey} />}
            />
        </div>
    );
}
