import useAuthContext from "../auth/useAuthContext";
import styles from "../../styles/admin/AdminDashboard.module.css";
import form_styles from "../../styles/forms/Forms.module.css";
import * as Switch from "@radix-ui/react-switch";

import { useState, useRef } from "react";
import { toast } from "react-toastify";
import BaseButton from "../base/BaseButton";
import Dropdown from "../base/Dropdown";
import { Card } from "./AdminDashboard";
function FlagSwitch({ flag, setRefreshKey }) {
    const { handleUpdateFeatureFlag } = useAuthContext();
    const [checked, setChecked] = useState(flag.is_active);
    const handleSwitchChange = (checked) => {
        const formData = {
            is_active: checked,
        };

        handleUpdateFeatureFlag(flag.name, formData)
            .then((_) => {
                toast.success("Feature flag updated successfully");
                setChecked(checked);
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((_) => toast.error("Error updating feature flag. See backend logs for more details"));
    };
    return (
        <div>
            <Switch.Root className={styles.switch_root} checked={checked} onCheckedChange={handleSwitchChange}>
                <Switch.Thumb className={styles.switch_thumb} />
            </Switch.Root>
        </div>
    );
}

function FeatureFlagsBody({ featureFlagsData, setRefreshKey }) {
    const { handleDeleteUserOverride } = useAuthContext();

    const flags = featureFlagsData.flags;
    if (!flags || flags.length === 0) {
        return <div>No feature flags</div>;
    }

    const handleDeleteUserOverrideClick = (flagName, email) => {
        handleDeleteUserOverride(flagName, email)
            .then((_) => {
                toast.success("User override deleted successfully");
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error || error?.response?.data?.detail);
            });
    };

    const flagsDisplay = (
        <ul className={styles.flags_list}>
            {flags.map((flag, index) => (
                <li key={index} className={styles.flags_list_item}>
                    <div className={styles.flag_info}>
                        <p className={styles.flag_title}>
                            <strong>{flag.name}</strong>
                        </p>
                        <p className={styles.flag_description}>{flag.description}</p>
                        <p className={styles.flag_description}>Hits in:</p>
                        <ul>
                            <li>Last 24 hours: {flag.usage_last_24_hours}</li>
                            <li>Last 7 days: {flag.usage_last_7_days}</li>
                        </ul>
                        <p className={styles.flag_description}>User overrides: {flag.user_overrides.length}</p>
                        <ul>
                            {flag.user_overrides.map((override, index) => (
                                <li className={styles.flag_user_override} key={index}>
                                    {override.email}: {override.is_active ? "True" : "False"}
                                    <BaseButton
                                        className={styles.flag_user_override_delete_button}
                                        onClick={() => handleDeleteUserOverrideClick(flag.name, override.email)}
                                    >
                                        Delete
                                    </BaseButton>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <FlagSwitch flag={flag} setRefreshKey={setRefreshKey} />
                </li>
            ))}
        </ul>
    );

    return <div className={styles.flags_container}>{flagsDisplay}</div>;
}

function AddFeatureFlagBody({ setRefreshKey }) {
    const { handleCreateFeatureFlag } = useAuthContext();
    const [rawFormData, setRawFormData] = useState({});

    const handleAddFeatureFlagClick = (event) => {
        event.preventDefault();

        const formData = {
            name: event.target.featureFlagName.value,
            description: event.target.featureFlagDescription.value,
            user_overrides: [],
        };

        handleCreateFeatureFlag(formData)
            .then((_) => {
                toast.success("Feature flag created successfully");
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((_) => toast.error("Error adding feature flag. See backend logs for more details"));
    };

    const handleFormInputChange = (event) => {
        setRawFormData({ ...rawFormData, [event.target.id]: event.target.value });
    };

    const shouldDisableButton =
        rawFormData.featureFlagName === undefined ||
        rawFormData.featureFlagName === "" ||
        rawFormData.featureFlagDescription === undefined ||
        rawFormData.featureFlagDescription === "";

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddFeatureFlagClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="featureFlagName">Feature Flag Name</label>
                    <input
                        id="featureFlagName"
                        type="text"
                        placeholder="ENABLE_KENNYKALMAN_OP_STATUS"
                        onChange={handleFormInputChange}
                        required
                    />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="featureFlagDescription">Feature Flag Description</label>
                    <input
                        id="featureFlagDescription"
                        type="text"
                        placeholder="Enable kennykalman's OP status"
                        onChange={handleFormInputChange}
                    />
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Add Feature Flag
                </BaseButton>
            </form>
        </div>
    );
}

function AddUserOverrideBody({ setRefreshKey, featureFlagsData }) {
    const [rawFormData, setRawFormData] = useState({});
    const { handleCreateUserOverride } = useAuthContext();
    const [selectedFeatureFlag, setSelectedFeatureFlag] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleFormInputChange = (event) => {
        setRawFormData({ ...rawFormData, [event.target.id]: event.target.value });
    };

    const handleAddUserOverrideClick = (event) => {
        event.preventDefault();

        const formData = {
            email: event.target.userOverrideEmail.value,
            is_active: event.target.userOverrideIsActive.checked,
        };

        handleCreateUserOverride(selectedFeatureFlag.name, formData)
            .then((_) => {
                toast.success("User override created successfully");
                setRefreshKey((prevKey) => prevKey + 1);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error || error?.response?.data?.detail);
            });
    };

    const shouldDisableButton =
        selectedFeatureFlag === null ||
        rawFormData.userOverrideEmail === undefined ||
        rawFormData.userOverrideEmail === "";

    const allFeatureFlagsDropdown = (
        <Dropdown
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            items={featureFlagsData.flags}
            dropdownRef={dropdownRef}
            selectedItem={selectedFeatureFlag}
            setSelectedItem={setSelectedFeatureFlag}
            placeholder={"Select the feature flag to add this user override to."}
        />
    );

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleAddUserOverrideClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="flagName">Flag Name</label>
                    {allFeatureFlagsDropdown}
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="userOverrideEmail">User Email</label>
                    <input
                        id="userOverrideEmail"
                        type="email"
                        placeholder="support@athenaco.ai"
                        onChange={handleFormInputChange}
                    />
                </div>
                <div className={form_styles.form_section}>
                    <label htmlFor="userOverrideIsActive">Is Active</label>
                    <input id="userOverrideIsActive" type="checkbox" onChange={handleFormInputChange} />
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Add User Override
                </BaseButton>
            </form>
        </div>
    );
}

export default function FlagsPage({ featureFlagsData, setRefreshKey }) {
    return (
        <div className={styles.page_container}>
            <Card
                title={"Flags"}
                body={<FeatureFlagsBody featureFlagsData={featureFlagsData} setRefreshKey={setRefreshKey} />}
            />
            <Card title={"Add Flag"} body={<AddFeatureFlagBody setRefreshKey={setRefreshKey} />} />
            <Card
                title={"Add User Override"}
                body={<AddUserOverrideBody setRefreshKey={setRefreshKey} featureFlagsData={featureFlagsData} />}
            />
        </div>
    );
}
