import { useEffect, useState } from "react";
import styles from "../../styles/consultant/GradeByLinkPage.module.css";
import BaseButton from "../base/BaseButton";
import { ReactComponent as GoogleDriveIcon } from "../../imgs/google-drive-icon.svg";

import { toast } from "react-toastify";

export default function GooglePicker(props) {
    const [gapiLoaded, setGapiLoaded] = useState(false);
    const { accessToken, handleAddFromGoogleDrive, handleConnectToGoogleDrive } = props;

    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        // Load the Google API script
        const loadGapi = () => {
            if (!document.querySelector('script[src="https://apis.google.com/js/api.js"]')) {
                const script = document.createElement("script");
                script.src = "https://apis.google.com/js/api.js";
                script.onload = () => setGapiLoaded(true);
                document.body.appendChild(script);
            } else {
                setGapiLoaded(true);
            }
        };

        loadGapi();
    }, []);

    const openPicker = () => {
        if (!accessToken) {
            toast.error("You need to authenticate first!");
            return;
        }

        if (!gapiLoaded) {
            toast.error("Google API not loaded yet");
            return;
        }

        // Load the picker module and create the picker
        window.gapi.load("picker", () => {
            const picker = new window.google.picker.PickerBuilder()
                .addView(window.google.picker.ViewId.DOCUMENTS)
                .setOAuthToken(accessToken)
                .setDeveloperKey(API_KEY)
                .setCallback(pickerCallback)
                .build();

            picker.setVisible(true);
        });
    };

    const pickerCallback = (data) => {
        if (data.action === window.google.picker.Action.PICKED) {
            const file = data.docs[0];
            handleAddFromGoogleDrive(file);
        }
    };

    const isGoogleDriveConnected = !!accessToken;

    const buttonOnClick = async () => {
        return isGoogleDriveConnected ? openPicker() : await handleConnectToGoogleDrive();
    };

    return (
        <BaseButton className={styles.connect_to_google_drive_button} onClick={buttonOnClick}>
            <GoogleDriveIcon className={styles.google_drive_icon} />
            {isGoogleDriveConnected ? "Add from Google Drive" : "Connect to Google Drive"}
        </BaseButton>
    );
}
