import styles from "../../../styles/consultant/GradeByLinkPage.module.css";
import BaseButton from "../../base/BaseButton";
import ReactMarkdown from "react-markdown";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Loading from "../../base/Loading";
import GreenCheckMark from "../../main/subcomponents/GreenCheckMark";
import GoogleDocComments from "../../application/sidebar/GoogleDocComments";
import { LightweightResponseFeedback } from "../../main/subcomponents/ResponseFeedback";
import useAuthContext from "../../auth/useAuthContext";
import { ReactComponent as EditIcon } from "../../../imgs/edit-icon.svg";
import { ReactComponent as ChevronUpIcon } from "../../../imgs/chevron-up-icon.svg";
import { pollResponse } from "../../../api/apiCalls";
import {
    STATIC_ATHENA_NAMESPACE_UUID,
    ExtractErrorMessage,
    FormatModelResponse,
    FormatIECGradingModelResponse,
} from "../../main/utils/utils";

import { Tooltip, TooltipTrigger, TooltipContent } from "../../base/Tooltip";
import playground_styles from "../../../styles/playground/Playground.module.css";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { v5 as uuidv5 } from "uuid";

// TODO (Eugene): Move to utils
function RubricExplanationToolTip(rubricExplanation) {
    if (rubricExplanation === undefined) {
        return;
    }
    return (
        <div className={styles.position_overlay}>
            <Tooltip placement="bottom">
                <TooltipTrigger className={playground_styles.no_background_tooltip_trigger}>
                    <img src="/info-icon.png" className={styles.info_icon} alt="info" />
                </TooltipTrigger>
                <TooltipContent className={playground_styles.rubric_explanation_tooltip}>
                    {rubricExplanation}
                </TooltipContent>
            </Tooltip>
        </div>
    );
}

// TODO (Eugene): Move to utils
function InDepthReview(props) {
    const [showInDepthReview, setShowInDepthReview] = useState(false);

    const displayText = showInDepthReview ? "Click to hide" : "Click to expand";

    const revealDiv = (
        <div className={styles.reveal_div} onClick={() => setShowInDepthReview(!showInDepthReview)}>
            <span>{displayText} </span>
            <ChevronUpIcon className={`${styles.chevron} ${!showInDepthReview && styles.rotated_chevron}`} />
        </div>
    );

    return (
        <>
            <h1>In-Depth Review</h1>
            {revealDiv}
            <div className={styles.model_response_container}>
                <div
                    className={`${styles.in_depth_review_container} ${
                        showInDepthReview ? styles.review_shown : styles.review_hidden
                    }`}
                >
                    <ReactMarkdown>{FormatModelResponse(props.feedback)}</ReactMarkdown>
                </div>
            </div>
        </>
    );
}

function EditableCard(props) {
    const { setExpandState, expandState, label, value, setValue } = props;
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = (event) => {
        event.stopPropagation();
        setIsEditing((prevState) => !prevState);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value);
    };

    const handleCardClick = (clickLocation) => {
        if (clickLocation === "card" && !expandState) {
            setExpandState(true);
        } else if (clickLocation === "header") {
            setIsEditing(false);
            setExpandState((prevState) => !prevState);
        }
    };

    return (
        <div className={styles.editable_card} onClick={() => handleCardClick("card")}>
            <div className={styles.editable_card_header} onClick={() => handleCardClick("header")}>
                <div className={styles.icon_container}>
                    <EditIcon className={styles.icon} onClick={handleEdit} />
                    <ChevronUpIcon className={`${styles.icon} ${!expandState && styles.rotated_chevron}`} />
                </div>
                <p className={styles.editable_card_label}>{label}</p>
            </div>
            {isEditing ? (
                <textarea className={styles.editable_card_textarea} value={value} onChange={handleInputChange} />
            ) : (
                <p className={`${styles.essay_prompt} ${!expandState && styles.collapsed}`}>{value}</p>
            )}
        </div>
    );
}

export default function EssayFeedbackScreen(props) {
    const {
        setScreen,
        isFetchingEssay,
        googleDocLink,
        googleDoc,
        selectedEssayType,
        selectedSchool,
        setIsFetchingEssay,
    } = props;
    const { handleGradeGoogleDocLink, handleGradeGoogleDoc } = useAuthContext();
    const [essayData, setEssayData] = useState({
        essay_prompt: "",
        essay_response: "",
    });
    const [expandEssayPrompt, setExpandEssayPrompt] = useState(false);
    const [expandEssayResponse, setExpandEssayResponse] = useState(false);

    // Manually define grade states
    const [modelResponseJSON, setModelResponseJSON] = useState("");
    const [gradeErrorMessage, setGradeErrorMessage] = useState("");
    const [isEssaySubmissionLoading, setIsEssaySubmissionLoading] = useState(false);
    const errorMessageRef = useRef(null);
    const [currentRequestId, setCurrentRequestId] = useState("");

    const handleResubmitGradeClick = () => {
        // TODO (Eugene): This resubmit is very hacky and has lots of duplicate code. I'll fix after I get some sleep.

        // Reset grade states
        setModelResponseJSON("");
        setGradeErrorMessage("");

        const timestamp = new Date().toISOString().slice(0, 19);
        const formData = {
            essay_type: selectedEssayType.metadata.essayType,
            school_code: "common_app", // ApplicationSchoolCode, default to common app for now
            idempotency_key: uuidv5(
                `${selectedEssayType.metadata.essayType}-${selectedSchool?.metadata?.schoolCode}-${timestamp}`,
                STATIC_ATHENA_NAMESPACE_UUID
            ),
            is_resubmission: true,
            essay_prompt: essayData.essay_prompt,
            essay_response: essayData.essay_response,
        };

        const formDataKey = googleDocLink ? "google_doc_link" : "document_id";
        const formDataValue = googleDocLink || googleDoc.id;
        const handleGradeFunction = googleDocLink ? handleGradeGoogleDocLink : handleGradeGoogleDoc;

        if (formDataValue) {
            formData[formDataKey] = formDataValue;

            handleGradeFunction(formData)
                .then((response) => {
                    toast.success("Essay fetched successfully");
                    setEssayData((prev) => ({
                        ...prev,
                        essay_prompt: response.data.essay_prompt,
                        essay_response: response.data.essay_response,
                    }));
                    setCurrentRequestId(response.data.report_card_v2_id);
                    setIsEssaySubmissionLoading(true);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.error);
                })
                .finally(() => {
                    setIsFetchingEssay(false);
                });
        }
    };

    useEffect(() => {
        if (!isFetchingEssay) return;

        // Reset grade states
        setModelResponseJSON("");
        setGradeErrorMessage("");

        const timestamp = new Date().toISOString().slice(0, 19);
        const formData = {
            // google_doc_link: googleDocLink,
            essay_type: selectedEssayType.metadata.essayType,
            school_code: "common_app", // ApplicationSchoolCode, default to common app for now
            idempotency_key: uuidv5(
                `${selectedEssayType.metadata.essayType}-${selectedSchool?.metadata?.schoolCode}-${timestamp}`,
                STATIC_ATHENA_NAMESPACE_UUID
            ),
        };

        const formDataKey = googleDocLink ? "google_doc_link" : "document_id";
        const formDataValue = googleDocLink || googleDoc.id;
        const handleGradeFunction = googleDocLink ? handleGradeGoogleDocLink : handleGradeGoogleDoc;

        if (formDataValue) {
            formData[formDataKey] = formDataValue;

            handleGradeFunction(formData)
                .then((response) => {
                    toast.success("Essay fetched successfully");
                    setEssayData((prev) => ({
                        ...prev,
                        essay_prompt: response.data.essay_prompt,
                        essay_response: response.data.essay_response,
                    }));
                    setCurrentRequestId(response.data.report_card_v2_id);
                    setIsEssaySubmissionLoading(true);
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.error);
                })
                .finally(() => {
                    setIsFetchingEssay(false);
                });
        }
    }, [
        isFetchingEssay,
        googleDocLink,
        googleDoc.id,
        handleGradeGoogleDocLink,
        handleGradeGoogleDoc,
        setScreen,
        setIsFetchingEssay,
        selectedEssayType?.metadata?.essayType,
        selectedSchool?.metadata?.schoolCode,
    ]);

    useEffect(() => {
        let intervalId;

        const shouldPollResponse = currentRequestId !== "" && modelResponseJSON === "" && gradeErrorMessage === "";

        function handlePollResponse(data) {
            if (data.gpt_response_status === "finished") {
                setModelResponseJSON(data.model_response_json);
                setIsEssaySubmissionLoading(false);
            } else if (data.gpt_response_status === "error") {
                setGradeErrorMessage(data.error_reason);
                setIsEssaySubmissionLoading(false);
            }
        }

        if (shouldPollResponse) {
            intervalId = setInterval(() => {
                pollResponse(currentRequestId, "ReportCardV2")
                    .then((response) => {
                        handlePollResponse(response.data);
                    })
                    .catch((error) => {
                        setIsEssaySubmissionLoading(false);
                        setGradeErrorMessage(ExtractErrorMessage(error));
                    });
            }, 5000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [currentRequestId, modelResponseJSON, gradeErrorMessage, setModelResponseJSON, setGradeErrorMessage]);

    const googleDocLinkDisplay = googleDocLink ? (
        <a href={googleDocLink} target="_blank" rel="noreferrer">
            {googleDocLink}
        </a>
    ) : googleDoc.url ? (
        <a href={googleDoc.url} target="_blank" rel="noreferrer">
            {googleDoc.url}
        </a>
    ) : null;

    return (
        <>
            <div className={styles.submission_details_container}>
                <div className={styles.submission_details}>
                    <div className={styles.detail}>
                        <p className={styles.detail_label}>Google Docs Link: </p>
                        <p className={styles.detail_value}>{googleDocLinkDisplay}</p>
                    </div>
                    <div className={styles.detail}>
                        <p className={styles.detail_label}>Essay Type: </p>
                        <p className={styles.detail_value}>
                            {selectedEssayType.name}
                            {selectedSchool ? `, ${selectedSchool.name}` : ""}
                        </p>
                    </div>
                </div>
                <BaseButton className={styles.restart_button} onClick={() => setScreen("link_submission")}>
                    Restart
                </BaseButton>
            </div>
            <div className={styles.divider} />
            {isFetchingEssay ? (
                <Loading />
            ) : (
                <div className={styles.essay_feedback_container}>
                    <div className={styles.essay_data_container}>
                        <h2 className={styles.essay_data_title}>Here's what we fetched from the Google Doc:</h2>
                        <EditableCard
                            label="Essay Prompt"
                            value={essayData.essay_prompt}
                            expandState={expandEssayPrompt}
                            setExpandState={setExpandEssayPrompt}
                            setValue={(value) => setEssayData((prev) => ({ ...prev, essay_prompt: value }))}
                        />
                        <EditableCard
                            label="Essay Response"
                            value={essayData.essay_response}
                            expandState={expandEssayResponse}
                            setExpandState={setExpandEssayResponse}
                            setValue={(value) => setEssayData((prev) => ({ ...prev, essay_response: value }))}
                        />
                        <BaseButton
                            className={`${styles.grade_button} ${isEssaySubmissionLoading && styles.disabled}`}
                            onClick={handleResubmitGradeClick}
                            disabled={isEssaySubmissionLoading}
                        >
                            Resubmit Grade
                        </BaseButton>
                    </div>
                    <div className={styles.essay_review_container}>
                        {modelResponseJSON.main_feedback || isEssaySubmissionLoading || gradeErrorMessage ? (
                            <div className={styles.essay_feedback}>
                                <div className={styles.loading_image}>
                                    {isEssaySubmissionLoading ? (
                                        <Loading />
                                    ) : (
                                        modelResponseJSON.main_feedback && <GreenCheckMark />
                                    )}
                                </div>
                                {gradeErrorMessage && (
                                    <div ref={errorMessageRef} className={styles.grade_error_message}>
                                        {gradeErrorMessage}
                                    </div>
                                )}
                                {modelResponseJSON.main_feedback && (
                                    <>
                                        <div
                                            className={`${styles.model_response_container} ${styles.grading_breakdown_parent}`}
                                        >
                                            <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                                                {FormatIECGradingModelResponse(modelResponseJSON.grading_breakdown)}
                                            </ReactMarkdown>
                                            {RubricExplanationToolTip(modelResponseJSON.rubric_explanation)}
                                        </div>
                                        <div className={styles.divider} />
                                        <div className={styles.essay_feedback_content}>
                                            <InDepthReview feedback={modelResponseJSON.main_feedback} />
                                        </div>
                                        <div className={styles.divider} />
                                        <GoogleDocComments
                                            modelResponseJSON={modelResponseJSON}
                                            reportCardV2ID={currentRequestId}
                                        />
                                        <div className={styles.divider} />
                                        <LightweightResponseFeedback currentRequestId={currentRequestId} />
                                    </>
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            )}
        </>
    );
}
