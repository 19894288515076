import { useCallback, useEffect, useRef } from "react";
import useAuthContext from "./useAuthContext";
import useUserContext from "./useUserContext";
import { toast } from "react-toastify";

const DEFAULT_TIMEOUT = 3600000; // 60 minutes in milliseconds
const TIMEOUT_WARNING_BUFFER = 300000; // 5 minutes in milliseconds
const MINIMUM_TIMEOUT = 1000; // 1 second in milliseconds
const DEBOUNCE_RESET_THRESHOLD = 1000; // 1 second in milliseconds
const LAST_ACTIVITY_KEY = "lastActivityTime";

export function useInactivityTimer(timeout = DEFAULT_TIMEOUT, timeoutWarningBuffer = TIMEOUT_WARNING_BUFFER) {
    const { handleUserLogout } = useAuthContext();
    const { userProfile } = useUserContext();

    const timeoutRef = useRef(null);
    const timeoutWarningRef = useRef(null);

    // Only apply inactivity timer to lausd users
    const shouldApplyInactivityTimer = useCallback(() => {
        if (!userProfile?.email) return false;

        return userProfile.email.endsWith("@mymail.lausd.net") || userProfile.email.endsWith("@lausd.net");
    }, [userProfile]);

    const resetTimer = useCallback(() => {
        clearTimeout(timeoutRef.current);
        clearTimeout(timeoutWarningRef.current);

        // Only apply inactivity timer to confirmed LAUSD users
        if (!shouldApplyInactivityTimer()) return;

        timeoutRef.current = setTimeout(() => {
            handleUserLogout("/login");
        }, timeout);
        timeoutWarningRef.current = setTimeout(() => {
            toast.warning("You will be logged out due to inactivity in 5 minutes.", {
                autoClose: timeoutWarningBuffer,
            });
        }, Math.max(timeout - timeoutWarningBuffer, MINIMUM_TIMEOUT));
    }, [shouldApplyInactivityTimer, timeout, timeoutWarningBuffer, handleUserLogout]);

    const updateLastActivity = useCallback(() => {
        localStorage.setItem(LAST_ACTIVITY_KEY, Date.now().toString());
        resetTimer();
    }, [resetTimer]);

    useEffect(() => {
        const handleStorageEvent = (event) => {
            if (event.key === LAST_ACTIVITY_KEY) {
                resetTimer();
            }
        };

        const handleActivity = () => {
            const now = Date.now();
            // Debounce to prevent excessive resets
            if (now - Number(localStorage.getItem(LAST_ACTIVITY_KEY)) > DEBOUNCE_RESET_THRESHOLD) {
                updateLastActivity();
            }
        };

        // Register activity listeners
        const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
        events.forEach((event) => window.addEventListener(event, handleActivity, { passive: true }));

        // Listen for storage events in other tabs
        window.addEventListener("storage", handleStorageEvent);

        // Initialize the timer
        resetTimer();

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleActivity));
            window.removeEventListener("storage", handleStorageEvent);
            clearTimeout(timeoutRef.current);
            clearTimeout(timeoutWarningRef.current);
        };
    }, [resetTimer, updateLastActivity]);

    return null;
}
