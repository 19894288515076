import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";
import styles from "../../styles/base/Loading.module.css";

export default function Loading() {
    return (
        <div className={styles.loading_section}>
            <LoadingSpinner className={styles.loading_spinner} />
        </div>
    );
}
