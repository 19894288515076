import { forwardRef, useEffect, useRef, useState } from "react";
import styles from "../../../styles/application/sidebar/ApplicationSidebar.module.css";
import playground_styles from "../../../styles/playground/Playground.module.css";
import useUserContext from "../../auth/useUserContext";
import useApplicationContext from "../useApplicationContext";
import { LightweightResponseFeedback } from "../../main/subcomponents/ResponseFeedback";
import InterpretFeedbackHyperlink from "../../main/subcomponents/InterpretFeedbackHyperlink";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../base/Tooltip.js";
import GreenCheckMark from "../../main/subcomponents/GreenCheckMark";
import LoadingSection from "../../main/subcomponents/LoadingSection";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import ReactMarkdown from "react-markdown";

import ChevronDownIcon from "../../../imgs/chevron-down-icon.png";
import ChevronRightIcon from "../../../imgs/chevron-right-icon.png";
import ChevronUpIcon from "../../../imgs/chevron-up-icon.png";
import DoubleChevronRightIcon from "../../../imgs/double-chevron-right-icon.png";
import NoEssayFeedback from "../../../imgs/no-essay-feedback.png";
import BaseButton from "../../base/BaseButton";
import BlueZapIcon from "../../../imgs/blue-zap-icon.png";
import GradeIcon from "../../../imgs/grade-icon.png";
import GoogleDocComments from "./GoogleDocComments";
import { ReactComponent as CheckIcon } from "../../../imgs/check-icon.svg";
import { ReactComponent as CopyIcon } from "../../../imgs/copy-icon.svg";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { doesAthenaProModelExist, getHistoryItem } from "../../../api/apiCalls";

import {
    FormatModelResponse,
    FormatIECGradingModelResponse,
    generateReadableHeaderTimestamp,
} from "../../main/utils/utils";
import { exportToPDF, exportToWord } from "../utils";

function EssayFeedback(props) {
    const { errorMessageRef } = props;
    const { frontendActiveProfile } = useUserContext();

    const {
        v2ModelResponseJSON,
        v2GradeErrorMessage,
        handleSubmitGradeRequest,
        isV2EssaySubmissionLoading,
        currentRequestId,
        editorStateStr,
    } = useApplicationContext();
    const navigate = useNavigate();

    const handleGradeClick = () => {
        try {
            handleSubmitGradeRequest(editorStateStr);
        } catch (error) {
            toast.error(error.message);
            return;
        }
    };

    const feedbackPDFRef = useRef(null);

    return (
        <div className={styles.essay_feedback_container}>
            {v2ModelResponseJSON.main_feedback || isV2EssaySubmissionLoading || v2GradeErrorMessage ? (
                <div className={styles.essay_feedback}>
                    <div className={styles.essay_feedback_header}>
                        <div className={styles.sidebar_section_title}>Athena's feedback</div>
                        <div className={styles.sidebar_section_subtitle}>Graded using Athena's Special IEC Model</div>
                    </div>
                    {v2ModelResponseJSON.main_feedback && <FeedbackExportSection feedbackPDFRef={feedbackPDFRef} />}
                    <div className={styles.loading_image}>
                        {isV2EssaySubmissionLoading ? (
                            <LoadingSection shouldShowText={true} />
                        ) : (
                            v2ModelResponseJSON.main_feedback && <GreenCheckMark />
                        )}
                    </div>
                    {v2GradeErrorMessage && (
                        <div ref={errorMessageRef} className={styles.grade_error_message}>
                            {v2GradeErrorMessage}
                        </div>
                    )}
                    {v2ModelResponseJSON.main_feedback && (
                        <>
                            <div className={`${styles.model_response_container} ${styles.grading_breakdown_parent}`}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                                    {FormatIECGradingModelResponse(v2ModelResponseJSON.grading_breakdown)}
                                </ReactMarkdown>
                                {RubricExplanationToolTip(v2ModelResponseJSON.rubric_explanation)}
                            </div>
                            <div className={styles.divider} />
                            <div className={styles.essay_feedback_content}>
                                <InDepthReview feedback={v2ModelResponseJSON.main_feedback} />
                            </div>
                            <div className={styles.divider} />
                            <GoogleDocComments />
                            <div className={styles.divider} />
                            <LightweightResponseFeedback currentRequestId={currentRequestId} />
                            <FeedbackPDF
                                modelResponseJSON={v2ModelResponseJSON}
                                googleDocComments={<GoogleDocComments />}
                                athenaModel={"IEC"}
                                ref={feedbackPDFRef}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.no_essay_feedback}>
                    <img src={NoEssayFeedback} className={styles.no_essay_feedback_img} alt="no feedback" />
                    <h3 className={styles.no_essay_feedback_title}>No feedback yet</h3>
                    <p className={styles.no_essay_feedback_text}>
                        Submit your draft when you're ready to receive personalized feedback. Ready to start?
                    </p>
                    <BaseButton className={styles.grade_button} onClick={handleGradeClick}>
                        <img className={styles.grade_icon} src={GradeIcon} alt="Grade Icon" />
                        Grade
                    </BaseButton>
                </div>
            )}
        </div>
    );
}

function FeedbackExportSection(props) {
    const { feedbackPDFRef } = props;
    return (
        <div className={styles.export_section}>
            <div className={styles.export_button_container}>
                <BaseButton className={styles.export_button} onClick={() => exportToPDF(feedbackPDFRef.current)}>
                    Export as PDF
                </BaseButton>
                <BaseButton className={styles.export_button} onClick={() => exportToWord(feedbackPDFRef.current)}>
                    Export as Word Doc
                </BaseButton>
            </div>
        </div>
    );
}

function InDepthReview(props) {
    const [showInDepthReview, setShowInDepthReview] = useState(false);
    const [isCopyClicked, setIsCopyClicked] = useState(false);

    const displayText = showInDepthReview ? "Click to hide" : "Click to expand";
    const handleCopy = () => {
        if (!feedbackRef.current) return;
        navigator.clipboard.writeText(feedbackRef.current.innerText);
        toast.success("Copied to clipboard");
    };

    const revealDiv = (
        <div className={styles.reveal_div} onClick={() => setShowInDepthReview(!showInDepthReview)}>
            <span>{displayText} </span>
            <img
                className={`${styles.chevron} ${showInDepthReview && styles.rotated_chevron}`}
                src={ChevronDownIcon}
                alt="arrow"
            />
        </div>
    );
    const feedbackRef = useRef(null);

    return (
        <>
            <h1>In-Depth Review</h1>
            <div className={styles.reveal_div_container}>
                {revealDiv}{" "}
                <Tooltip placement="bottom">
                    <TooltipTrigger className={styles.icon_button} onClick={handleCopy}>
                        {isCopyClicked ? <CheckIcon className={styles.icon} /> : <CopyIcon className={styles.icon} />}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p className={styles.modal_info_tooltip_text}>{isCopyClicked ? "Copied!" : "Copy"}</p>
                    </TooltipContent>
                </Tooltip>
            </div>
            <div className={styles.model_response_container}>
                <div
                    className={`${styles.in_depth_review_container} ${
                        showInDepthReview ? styles.review_shown : styles.review_hidden
                    }`}
                    ref={feedbackRef}
                >
                    <ReactMarkdown>{FormatModelResponse(props.feedback)}</ReactMarkdown>
                </div>
            </div>
        </>
    );
}

function PreviousSubmissionItem(props) {
    const { item, setSelectedItem, setShowPreviousSubmissionDisplay } = props;
    const handleClick = () => {
        setSelectedItem(item);
        setShowPreviousSubmissionDisplay(true);
    };

    return (
        <li className={styles.previous_submissions_list_item} onClick={handleClick}>
            <div className={styles.submission_info}>
                <span className={styles.submission_preview}>{item.essayPreview}</span>
                <span className={styles.submission_date}>{item.date}</span>
            </div>
            <img className={styles.chevron} src={ChevronRightIcon} alt="arrow" />
        </li>
    );
}

function RubricExplanationToolTip(rubricExplanation) {
    if (rubricExplanation === undefined) {
        return;
    }
    return (
        <div className={styles.position_overlay}>
            <Tooltip placement="bottom">
                <TooltipTrigger className={playground_styles.no_background_tooltip_trigger}>
                    <img src="/info-icon.png" className={styles.info_icon} />
                </TooltipTrigger>
                <TooltipContent className={playground_styles.rubric_explanation_tooltip}>
                    {rubricExplanation}
                </TooltipContent>
            </Tooltip>
        </div>
    );
}

function V2PreviousSubmissionDisplay(props) {
    const { selectedItem, setShowPreviousSubmissionDisplay } = props;
    const [submission, setSubmission] = useState(null);
    const [googleDocComments, setGoogleDocComments] = useState(null);

    // Fetch selected history item
    useEffect(() => {
        if (!selectedItem) return;

        getHistoryItem(selectedItem.historyId, "ReportCardV2")
            .then((response) => {
                const rawSubmission = response.data;
                const formattedSubmission = {
                    essay: rawSubmission.initial_essay,
                    modelResponseJSON: rawSubmission.model_response_json,
                    // date: new Date(rawSubmission.created_at).toLocaleString(), not currently used
                    athenaModel: rawSubmission.athena_model,
                    supplementalEssayCategory: rawSubmission.supplemental_essay_category,
                };
                setSubmission(formattedSubmission);
            })
            .catch((error) => toast.error(`Error fetching history item: ${error?.response?.data?.error}`));
    }, [selectedItem]);

    useEffect(() => {
        if (submission?.modelResponseJSON) {
            setGoogleDocComments(
                <GoogleDocComments
                    modelResponseJSON={submission.modelResponseJSON}
                    reportCardV2ID={selectedItem.historyId}
                />
            );
        }
    }, [submission]);

    let athenaModel = "";
    switch (submission?.athenaModel) {
        case "flagship":
            athenaModel = "Flagship";
            break;
        case "pro":
            athenaModel = "Athena Pro";
            break;
        default:
            break;
    }

    const feedbackPDFRef = useRef(null);
    return (
        <div className={styles.previous_submission_display_container}>
            <div className={styles.submission_header}>
                <div className={styles.submission_info}>
                    <div className={styles.submission_name}>{selectedItem?.essayPreview}</div>
                    <div className={styles.submission_timestamp}>{selectedItem?.date}</div>
                </div>
                <BaseButton className={`${styles.button}`} onClick={() => setShowPreviousSubmissionDisplay(false)}>
                    <img
                        className={styles.button_icon}
                        src={DoubleChevronRightIcon}
                        alt="button for closing athena sidebar"
                    />
                </BaseButton>
            </div>
            <div className={styles.submission_body}>
                <div className={styles.submission_essay_container}>
                    <div className={styles.submission_essay_title}>Your Submission</div>
                    <div>{submission?.essay}</div>
                </div>
                <div className={styles.submission_model_response_container}>
                    {/* Section 1: Header */}
                    <div className={styles.submission_model_response_header}>
                        <div className={styles.submission_model_response_title}>Athena's feedback</div>
                        <div className={styles.submission_model_response_athena_model}>{athenaModel + " Model"}</div>
                    </div>

                    <FeedbackExportSection feedbackPDFRef={feedbackPDFRef} />

                    {/* Section 2: Grading breakdown */}
                    <div className={`${styles.model_response_container} ${styles.grading_breakdown_parent}`}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                            {FormatIECGradingModelResponse(submission?.modelResponseJSON?.grading_breakdown)}
                        </ReactMarkdown>
                        {RubricExplanationToolTip(submission?.modelResponseJSON?.rubric_explanation)}
                    </div>
                    <div className={styles.divider} />

                    {/* Section 3: In-depth review */}
                    <div className={styles.essay_feedback_content}>
                        <h2 className={styles.essay_feedback_title}>In-Depth Review</h2>
                        <div className={styles.model_response_container}>
                            <ReactMarkdown>
                                {FormatModelResponse(submission?.modelResponseJSON?.main_feedback)}
                            </ReactMarkdown>
                        </div>
                    </div>

                    {/* Section 4: Google doc comments */}
                    {googleDocComments}
                    <div className={styles.divider} />
                </div>
            </div>
            <FeedbackPDF
                modelResponseJSON={submission?.modelResponseJSON}
                googleDocComments={googleDocComments}
                athenaModel={athenaModel}
                ref={feedbackPDFRef}
            />
        </div>
    );
}

const FeedbackPDF = forwardRef(function FeedbackPDF(props, ref) {
    const { modelResponseJSON, googleDocComments, athenaModel } = props;

    return (
        <div ref={ref} className={styles.pdf_container} data-node-depth={3}>
            {/* Section 1: Header */}
            <div className={styles.feedback_pdf_header} data-leaf-node>
                <div className={styles.feedback_pdf_header_row}>
                    <span className={styles.feedback_pdf_timestamp_text}>{generateReadableHeaderTimestamp()}</span>
                </div>
                <div className={styles.feedback_pdf_header_row}>
                    <div className={styles.feedback_pdf_athena_model}>
                        {"Graded using our internal evaluation engine based on past successful essays."}
                    </div>
                </div>
            </div>

            {/* Section 2: Grading breakdown */}
            <div className={`${styles.model_response_container} ${styles.grading_breakdown_parent}`} data-leaf-node>
                <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
                    {FormatIECGradingModelResponse(modelResponseJSON?.grading_breakdown)}
                </ReactMarkdown>
            </div>

            {/* Section 3: In-depth review */}
            <div className={styles.essay_feedback_content}>
                <h2 className={styles.essay_feedback_title} data-leaf-node>
                    In-Depth Review
                </h2>
                <div className={styles.model_response_container}>
                    <ReactMarkdown>{FormatModelResponse(modelResponseJSON?.main_feedback)}</ReactMarkdown>
                </div>
            </div>

            {/* Section 4: Google doc comments */}
            <div className={styles.comments_pdf_container}>{googleDocComments}</div>
        </div>
    );
});

export default function EssayReviewV2(props) {
    const [showSubmission, setShowSubmission] = useState(false);
    const [showV2PreviousSubmissions, setShowV2PreviousSubmissions] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const {
        essaySubmission,
        v2GradeErrorMessage,
        v2PreviousSubmissions,
        showV2PreviousSubmissionDisplay,
        setShowV2PreviousSubmissionDisplay,
    } = useApplicationContext();
    const errorMessageRef = useRef(null);

    // Slightly jank way to close the previous submission display when the sidebar tab is switched
    // TODO (Eugene): Fix entire state management flow
    useEffect(() => {
        setShowV2PreviousSubmissionDisplay(false);
    }, [setShowV2PreviousSubmissionDisplay]);

    useEffect(() => {
        if (v2GradeErrorMessage && errorMessageRef.current) {
            setTimeout(() => {
                errorMessageRef?.current?.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500); // delay scroll to error to avoid interfering with sidebar opening animation
        }
    }, [v2GradeErrorMessage, errorMessageRef]); // We might not need this because it should already be in view?

    const handleShowSubmission = () => {
        if (!essaySubmission) return;
        setShowSubmission(!showSubmission);
    };

    return (
        <div className={styles.athena_essay_reviews}>
            <div className={styles.essay_submission}>
                <div className={styles.essay_submission_header} onClick={handleShowSubmission}>
                    <span className={styles.sidebar_section_title}>Your Submission</span>
                    <img
                        className={`${styles.chevron} ${showSubmission && styles.rotated_chevron}`}
                        src={ChevronDownIcon}
                        alt="chevron"
                    />
                </div>
                {/* TODO (Eugene): Make sure this essay submission can display markdown */}
                {showSubmission && essaySubmission && (
                    <div className={styles.essay_submission_content}>{essaySubmission}</div>
                )}
            </div>
            <EssayFeedback errorMessageRef={errorMessageRef} />
            <div className={styles.previous_submissions}>
                <div
                    className={styles.previous_submissions_header}
                    onClick={() => setShowV2PreviousSubmissions(!showV2PreviousSubmissions)}
                >
                    <span className={styles.sidebar_section_title}>Previous Submissions</span>
                    <img
                        className={`${styles.chevron} ${showV2PreviousSubmissions && styles.rotated_chevron}`}
                        src={ChevronUpIcon}
                        alt="chevron"
                    />
                </div>
                {showV2PreviousSubmissions &&
                    (v2PreviousSubmissions.length > 0 ? (
                        <ul className={styles.previous_submissions_list}>
                            {v2PreviousSubmissions.map((item, index) => (
                                <PreviousSubmissionItem
                                    key={index}
                                    item={item}
                                    setSelectedItem={setSelectedItem}
                                    setShowPreviousSubmissionDisplay={setShowV2PreviousSubmissionDisplay}
                                />
                            ))}
                        </ul>
                    ) : (
                        <div className={styles.no_previous_submissions}>No previous submissions</div>
                    ))}
            </div>
            {showV2PreviousSubmissionDisplay && (
                <V2PreviousSubmissionDisplay
                    selectedItem={selectedItem}
                    setShowPreviousSubmissionDisplay={setShowV2PreviousSubmissionDisplay}
                />
            )}
        </div>
    );
}
