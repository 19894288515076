import { useEffect, useState } from "react";
import styles from "../../styles/applicant/ActivityDescription.module.css";
import tools_styles from "../../styles/consultant/ConsultantToolsPage.module.css";
import form_styles from "../../styles/forms/Forms.module.css";
import comment_styles from "../../styles/application/sidebar/ApplicationSidebar.module.css";
import { toast } from "react-toastify";
import useAuthContext from "../auth/useAuthContext";
import useUserContext from "../auth/useUserContext";
import { reviseActivityDescription } from "../../api/apiCalls";
import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";
import BaseButton from "../base/BaseButton";
import { Tooltip, TooltipContent, TooltipTrigger } from "../base/Tooltip";
import { ReactComponent as CopyIcon } from "../../imgs/copy-icon.svg";
import { ReactComponent as CheckIcon } from "../../imgs/check-icon.svg";
import Loading from "../base/Loading";

export default function ActivityDescription() {
    const [activityDescription, setActivityDescription] = useState("");
    const [maxCharCount, setMaxCharCount] = useState("");
    const [isLoadingRevision, setIsLoadingRevision] = useState(false);
    const [revisionData, setRevisionData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoadingRevision(true);
        setRevisionData(null);

        try {
            const formData = {
                activity_description: activityDescription,
                max_char_count: parseInt(maxCharCount),
            };

            const response = await reviseActivityDescription(formData);
            setRevisionData(response.data);
            toast.success("Activity description revised successfully!");
        } catch (error) {
            toast.error(error?.response?.data?.error || "Failed to revise activity description");
        } finally {
            setIsLoadingRevision(false);
        }
    };

    const shouldDisableButton = activityDescription === "" || maxCharCount === "";
    return (
        <div className={tools_styles.page_container}>
            <div className={tools_styles.page_header}>
                <h1 className={tools_styles.title}>Revise An Activity Description</h1>
            </div>
            <div className={tools_styles.page_content}>
                <div className={form_styles.form_container}>
                    <form onSubmit={handleSubmit} className={form_styles.form}>
                        <div className={form_styles.form_long_section}>
                            <label htmlFor="activity-description">Existing Activity Description</label>
                            <textarea
                                id="activity-description"
                                onChange={(event) => setActivityDescription(event.target.value)}
                                rows="4"
                            />
                        </div>
                        <div className={form_styles.form_section}>
                            <label htmlFor="max-char-count">Maximum Characters Allowed</label>
                            <input
                                type="number"
                                id="max-char-count"
                                onChange={(event) => setMaxCharCount(event.target.value)}
                            />
                        </div>
                        <BaseButton
                            className={`${form_styles.form_button} ${
                                shouldDisableButton && form_styles.disabled_form_button
                            }`}
                            disabled={shouldDisableButton}
                        >
                            Revise
                        </BaseButton>
                        {isLoadingRevision ? <Loading /> : null}
                        {/* {revisionData && (
                            <div className={styles.activity_revision_container}>
                                <div className={comment_styles.comments_container}>
                                    <div className={comment_styles.comment}>
                                        <h3>Revised Description:</h3>
                                        <p>
                                            {revisionData.revision} ({revisionData.revision.length} characters)
                                        </p>
                                    </div>
                                </div>
                                <div className={comment_styles.comments_container}>
                                    <div className={comment_styles.comment}>
                                        <h3>Explanation:</h3>
                                        <p>{revisionData.critique}</p>
                                    </div>
                                </div>
                            </div>
                        )} */}
                    </form>
                    {revisionData && (
                        <div className={styles.activity_revision_container}>
                            <CopyableComment
                                title="Revised Description:"
                                body={revisionData.revision}
                                extra={`(${revisionData.revision.length} characters)`}
                            />
                            <CopyableComment title="Explanation:" body={revisionData.critique} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function CopyableComment(props) {
    const [isCopyClicked, setIsCopyClicked] = useState(false);

    const handleCopy = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy);
        setIsCopyClicked(true);
        setTimeout(() => {
            setIsCopyClicked(false);
        }, 2000);
    };

    return (
        <div className={comment_styles.comments_container}>
            <div className={comment_styles.comment}>
                <h3>{props.title}</h3>
                <p>
                    {props.body} {props.extra}
                </p>
                <div className={comment_styles.comment_action_bar}>
                    <Tooltip placement="bottom">
                        <TooltipTrigger className={comment_styles.icon_button} onClick={() => handleCopy(props.body)}>
                            {isCopyClicked ? (
                                <CheckIcon className={comment_styles.icon} />
                            ) : (
                                <CopyIcon className={comment_styles.icon} />
                            )}
                        </TooltipTrigger>
                        <TooltipContent>
                            <p className={comment_styles.modal_info_tooltip_text}>
                                {isCopyClicked ? "Copied!" : "Copy"}
                            </p>
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}
