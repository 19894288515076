import styles from "../../styles/consultant/IECMarketplace.module.css";
import BaseButton from "../base/BaseButton";

function IECCard(props) {
    const { title, description } = props;

    return (
        <div className={styles.card}>
            <h2 className={styles.card_title}>{title}</h2>
            <p className={styles.card_description}>{description}</p>
            <div className={styles.card_section}>
                <BaseButton className={styles.card_button} onClick={() => alert("I don't exist yet")}>
                    Contact Us
                </BaseButton>
            </div>
        </div>
    );
}

export default function IECMarketplace() {
    // TODO: Fetch IEC cards from backend?
    const IEC_CARDS = [
        {
            title: "IEC 1",
            description: "I am an IEC",
        },
        {
            title: "IEC 2",
            description: "I am also an IEC",
        },
        {
            title: "IEC 3",
            description: "I am the third IEC",
        },
    ];

    return (
        <div className={styles.page_container}>
            <h1 className={styles.page_title}>IEC Marketplace</h1>
            {IEC_CARDS.map((card, index) => (
                <IECCard key={index} title={card.title} description={card.description} />
            ))}
        </div>
    );
}
