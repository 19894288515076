import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "../../styles/auth/IECGoogleDocsOAuthPage.module.css";
import { ReactComponent as LoadingSpinner } from "../../imgs/loading.svg";
import BaseButton from "../base/BaseButton";

import { sendGoogleOAuthCode } from "../../api/apiCalls";

function ErrorPage({ errorMessage }) {
    const navigate = useNavigate();
    return (
        <div className={styles.error_page_container}>
            <div className={styles.error_message}>Oops! Something went wrong.</div>
            <div className={styles.error_description}>
                <p>There was a problem while trying to authenticate with Google Docs.</p>
                <p> If this issue persists, please reach out to support@athenaco.ai.</p>
                <p>
                    Error Message: <b>{errorMessage}</b>
                </p>
            </div>
            <BaseButton className={styles.button} onClick={() => navigate("/")}>
                Back to site
            </BaseButton>
        </div>
    );
}

const LoadingPage = () => {
    return (
        <div className={styles.loading_page_container}>
            <LoadingSpinner className={styles.loading_page_spinner} />
            <div className={styles.loading_message}>Authenticating with Google Docs...</div>
        </div>
    );
};

export default function IECGoogleDocsOAuthPage() {
    const [loginError, setLoginError] = useState("");

    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const navigate = useNavigate();

    useEffect(() => {
        if (!code) return;
        const formData = { code: code, state: state };

        // Submit code to backend to set access token and refresh token for IEC Google Docs
        sendGoogleOAuthCode(formData)
            .then((response) => {
                const redirectPath = response.data.redirect_path;
                navigate(redirectPath);
            })
            .catch((error) => setLoginError(error?.response?.data?.error));
    }, [code, state, navigate]);

    if (!code) {
        return <ErrorPage errorMessage={"Missing authorization code"} />;
    }

    return (
        <div className={styles.oauth_page_container}>
            {loginError ? <ErrorPage errorMessage={loginError} /> : <LoadingPage />}
        </div>
    );
}
