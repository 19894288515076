import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// axios.defaults.withCredentials = true;

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function userLogin(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/login",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function userGoogleLogin(jwtToken) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/google_login",
        withCredentials: true,
    };
    return axios(options);
}

export function userLogOut() {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/logout",
        withCredentials: true,
    };
    return axios(options);
}

export function userGoogleSignupViaAccessToken(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/google_signup_via_access_token",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function userSignup(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/signup",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function processAccountSetup(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/account_setup",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function sendAccountLinkingRequest(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/send_account_linking_request",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function deleteAccountLinkingRequest(formData) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/delete_account_linking_request",
        data: formData /* ok apparently we're not supposed to do this. Luckily, this will be deprecated soon */,
        withCredentials: true,
    };
    return axios(options);
}

export function linkAccount(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/link_account",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function unlinkAccount(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/unlink_account",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function sendEmailVerification(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/send_email_verification",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function verifyEmail(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/verify_email`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function checkPasswordResetLink(userId, token) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/check_password_reset_link?user_id=${userId}&token=${token}`,
        withCredentials: true,
    };
    return axios(options);
}

export function userResetPassword(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/user_reset_password",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function sendPasswordResetEmail(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/send_password_reset_email",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function ping() {
    const options = {
        method: "GET",
        url: BASE_URL + "/api/v1/authentication/ping",
        withCredentials: true,
    };
    return axios(options);
}

export function checkAuthentication() {
    const options = {
        method: "GET",
        url: BASE_URL + "/api/v1/authentication/check_authentication",
        withCredentials: true,
    };
    return axios(options);
}

export function getUserProfile() {
    const options = {
        method: "GET",
        url: BASE_URL + "/api/v1/authentication/user",
        withCredentials: true,
    };
    return axios(options);
}

// POST calls for actual grading
export function gradeCommonAppEssay(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/grade/common_app_essay",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function gradeSupplementalEssay(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/grade/supplemental_essay",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function gradeScholarshipEssay(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/grade/scholarship_essay",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call for submitting essay response feedback
export function submitEssayResponseFeedback(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/grade/essay_response_feedback",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

export function pollResponse(requestID, dbModel = "") {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/grade/poll_response?request_id=${requestID}&db_model=${dbModel}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call for fetching history item view
export function getHistoryItem(requestID, dbModel = "") {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/history/item?request_id=${requestID}&db_model=${dbModel}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call for fetching previews for all history items
export function getAllHistoryPreviews(numPreviewChars) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/history/all?num_preview_chars=${numPreviewChars}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call for creating Stripe checkout session
export function createCheckoutSession(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/stripe/checkout`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call for creating Stripe Billing Portal session
export function createBillingPortalSession(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/stripe/billing-portal`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to manually fulfill pending Stripe checkout sessions
export function fulfillCheckoutSession(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/stripe/fulfill-checkout`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call for fetching applicant's background data
export function fetchApplicantBackgroundData() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/applicant-background`,
        withCredentials: true,
    };
    return axios(options);
}

// PUT call for saving applicant's background data
export function saveApplicantBackgroundData(formData) {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/applicant-background`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all college essays for a specific application
export function fetchApplicationCollegeEssays(applicationId) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/college-application?application_id=${applicationId}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call for fetching saved essay data
export function fetchCollegeEssayData(essayId) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/essay?essay_id=${essayId}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create new essay for prompt
export function createCollegeEssay(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// PUT call for saving essay data
export function saveCollegeEssayData(essayId, formData) {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/${essayId}`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call for submitting brainstorm request
export function submitBrainstormRequest(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/brainstorm`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call for submitting progress check request
export function submitProgressCheckRequest(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/progress-check`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call for fetching previous chats submitted to Athena Chat
export function fetchChatHistory(essayId, numMessages = 50) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/essay/chat-history/${essayId}?num_messages=${numMessages}`,
        withCredentials: true,
    };
    return axios(options);
}

// DELETE call for deleting a specific chat message or all history for a specific essay
export function deleteChatMessage(essayId, chatMessageId) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/chat-history/${essayId}?chat_message_id=${chatMessageId}`,
        withCredentials: true,
    };
    return axios(options);
}

export function deleteAllChatMessages(essayId) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/chat-history/${essayId}`,
        withCredentials: true,
    };
    return axios(options);
}

// DEPRECATED - POST call for submitting question to Athena
export function submitQuestion(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/question`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all active applications
export function fetchAllApplications(includeCommonApp = false) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/applicant-application?include_common_app=${includeCommonApp}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create new application
export function createApplication(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/applicant-application`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// PUT call to update application
export function updateApplication(applicationId, formData) {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/applicant-application/${applicationId}`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// DELETE call to delete application
export function deleteApplication(applicationId) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/applicant-application/${applicationId}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch previous grade submissions for a specific essay
export function fetchPreviousEssayGradeSubmissions(essayId, version) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/essay/grade-history?essay_id=${essayId}&version=${version}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch common app application for user
export function fetchCommonAppApplication() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/common-app`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch if Athena Pro model exists for a given school
export function doesAthenaProModelExist(application_school_code) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/grade/does_pro_model_exist?application_school_code=${application_school_code}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch playground application for user
export function fetchPlaygroundApplication() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/playground`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all playground custom prompts
export function fetchPlaygroundPrompts(applicationId) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/playground/application?application_id=${applicationId}`,
        withCredentials: true,
    };
    return axios(options);
}

// DELETE call to delete college essay (custom prompts) - ONLY FOR PLAYGROUND + SCHOLARSHIP USE
export function deleteCustomPrompt(essayId) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/custom-prompt/${essayId}`,
        withCredentials: true,
    };
    return axios(options);
}

// PUT call to update custom prompt - ONLY FOR PLAYGROUND + SCHOLARSHIP USE
export function updateCustomPrompt(essayId, formData) {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/essay/custom-prompt/${essayId}`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch if the user has completed the quiz
export function fetchIsQuizCompleted() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/quiz-completed`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to mark the quiz as completed
export function markQuizAsCompleted(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/applicant/quiz-completed",
        withCredentials: true,
    };
    return axios(options);
}

// POST call to handle Clever login
export function cleverLogin(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: BASE_URL + "/api/v1/authentication/clever-login",
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET admin dashboard for easy monitoring
export function fetchAdminDashboard() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard`,
        withCredentials: true,
    };
    return axios(options);
}

// GET admin dashboard last 50 users page
export function fetchAdminDashboardLast50Users(page) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard/last-50-users?page=${page}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET admin dashboard feature flags
export function fetchFeatureFlags() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/gatekeeper/feature-flag`,
        withCredentials: true,
    };
    return axios(options);
}

// GET admin dashboard specific feature flag
export function fetchFeatureFlag(flagName) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/gatekeeper/feature-flag/${flagName}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create new feature flag
export function createFeatureFlag(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/gatekeeper/feature-flag`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// PUT call to update feature flag
export function updateFeatureFlag(flagName, formData) {
    const options = {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/gatekeeper/feature-flag/${flagName}`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch IP Geolocation data
export function fetchIPGeolocation() {
    const options = {
        method: "GET",
        url: `https://ipinfo.io/?token=${process.env.REACT_APP_IP_GEOLOCATION_API_KEY}`,
    };
    return axios(options);
}

// GET call to fetch consultant organization data
export function fetchConsultantOrganizationData() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/consultant/organization`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all registered students of a consultant
export function fetchConsultantRegisteredStudents(isAdminSeesAllStudents) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/consultant/students?is_admin_sees_all_students=${isAdminSeesAllStudents}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to register a student for a consultant
export function registerConsultantStudent(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/consultant/students`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to get the current student for a consultant
export function fetchConsultantCurrentStudent() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/consultant/current-student`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to set the current student for a consultant
export function assignConsultantCurrentStudent(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/consultant/current-student`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create a consultant organization
export function createConsultantOrganization(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard/create-consultant-organization`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to add new special user
// Handles both cases where they have already signed up or haven't signed up yet
export function createSpecialUserSetup(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard/create-special-user-setup`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all licenses
export function fetchLicenses() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard/fetch-licenses`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch all consultant organizations (needed for dropdown)
export function fetchAllConsultantOrganizations() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/admin-dashboard/fetch-all-consultant-organizations`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to determine feature gating of Report Card V2
export function shouldShowReportCardV2() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/grade/should_show_report_card_v2`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to leave feedback for google doc comments
export function createGoogleDocCommentFeedback(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/grade/google-doc-comment-feedback`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch user's referral code
export function fetchReferralCode() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/referral-code`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create a new referral code
export function createReferralCode(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/referral-code`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch usage for a referral code
export function fetchReferralCodeUsage(referralCode, promotion) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/referral-code/count?referral_code=${referralCode}&promotion=${promotion}`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch scholarships application
export function fetchScholarshipsApplication() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/scholarship`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch scholarships prompts
export function fetchScholarshipsPrompts(applicationId) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/applicant/scholarship/application?application_id=${applicationId}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to create a user override
export function createUserOverride(flagName, formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/gatekeeper/feature-flag/${flagName}/user-override`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// DELETE call to delete a user override
export function deleteUserOverride(flagName, email) {
    const options = {
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/gatekeeper/feature-flag/${flagName}/user-override`,
        data: { email },
        withCredentials: true,
    };
    return axios(options);
}

// POST call to grade a google doc link
export function gradeGoogleDocLink(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/grade/google-doc-link-grade`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to grade a google doc
export function gradeGoogleDoc(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/grade/google-doc-grade`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to revise activity description
export function reviseActivityDescription(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/applicant/activities-list`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch service status
export function fetchServiceStatus(serviceName) {
    const options = {
        method: "GET",
        url: `${BASE_URL}/system/service-status?service_name=${serviceName}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to refresh service status
export function refreshServiceStatus(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/system/refresh-service-status`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to deactivate account
export function deactivateAccount() {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/deactivate-account`,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to fetch authorization URL for connecting to Google Drive
export function fetchGoogleDriveAuthorizationURL(redirectPath = "/") {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/iec-google-docs-auth?redirect_path=${redirectPath}`,
        withCredentials: true,
    };
    return axios(options);
}

// POST call to set Google OAuth credentials for a user
export function sendGoogleOAuthCode(formData) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
        url: `${BASE_URL}/api/v1/authentication/iec-google-docs-auth`,
        data: formData,
        withCredentials: true,
    };
    return axios(options);
}

// GET call to check if user is authenticated with Google Docs
export function checkGoogleDocsAuthentication() {
    const options = {
        method: "GET",
        url: `${BASE_URL}/api/v1/authentication/iec-google-docs-check-authentication`,
        withCredentials: true,
    };
    return axios(options);
}
