export const toCamelCase = (str) => {
    return str.replace(/([-_][a-z])/gi, (match) => {
        return match.toUpperCase().replace("-", "").replace("_", "");
    });
};

export const keysToCamelCase = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => keysToCamelCase(v));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            result[toCamelCase(key)] = keysToCamelCase(obj[key]);
            return result;
        }, {});
    }
    return obj;
};

export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
};
