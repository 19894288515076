import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { getHistoryItem } from "../../api/apiCalls";
import { formatTimestamp } from "../../api/utils";
import { Card } from "./AdminDashboard";
import form_styles from "../../styles/forms/Forms.module.css";
import styles from "../../styles/admin/AdminDashboard.module.css";
import BaseButton from "../base/BaseButton";
import ReactMarkdown from "react-markdown";
import Dropdown from "../base/Dropdown";
import { dropdownSchoolList, dropdownSchoolListSearchTermMapping } from "../application/schoolList";

function DebugBody() {
    const [reportCardId, setReportCardId] = useState("");
    const [historyItem, setHistoryItem] = useState({});

    const shouldDisableButton = reportCardId === "";

    const handleFormInputChange = (event) => {
        setReportCardId(event.target.value);
    };

    const handleFetchReportCard = (event) => {
        event.preventDefault();

        getHistoryItem(reportCardId, "ReportCardV2")
            .then((response) => {
                setHistoryItem(response.data);
            })
            .catch((error) => toast.error(error?.response?.data?.error || error?.response?.data?.detail));
    };

    return (
        <>
            <div className={form_styles.form_container}>
                <form className={form_styles.form} onSubmit={(event) => handleFetchReportCard(event)}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="reportCardId">Report Card ID</label>
                        <input
                            id="reportCardId"
                            type="text"
                            placeholder="1234567890"
                            onChange={handleFormInputChange}
                            required
                        />
                    </div>
                    <BaseButton
                        className={`${form_styles.form_button} ${
                            shouldDisableButton && form_styles.disabled_form_button
                        }`}
                        disabled={shouldDisableButton}
                    >
                        Fetch History
                    </BaseButton>
                </form>
            </div>
            <div className={styles.debug_output_container}>
                <p className={styles.debug_output_title}>History Item:</p>
                <div className={styles.model_response_container}>
                    <span className={styles.debug_output_section_title}>Initial Essay: </span>
                    <p>{historyItem.initial_essay}</p>

                    <div className={styles.divider} />

                    <span className={styles.debug_output_section_title}>Google Doc Comments: </span>
                    <ul>
                        {historyItem?.model_response_json?.google_doc_comments.map((comment, index) => (
                            <li key={index}>
                                <p>{comment.comment}</p>
                                <p>{comment.highlighted_text}</p>
                            </li>
                        ))}
                    </ul>
                    <span className={styles.debug_output_section_title}>Grading Breakdown: </span>
                    <ReactMarkdown>{historyItem?.model_response_json?.grading_breakdown}</ReactMarkdown>

                    <div className={styles.divider} />

                    <span className={styles.debug_output_section_title}>Main Feedback: </span>
                    <ReactMarkdown>{historyItem?.model_response_json?.main_feedback}</ReactMarkdown>

                    <div className={styles.divider} />

                    <span className={styles.debug_output_section_title}>Rubric Explanation: </span>
                    <ReactMarkdown>{historyItem?.model_response_json?.rubric_explanation}</ReactMarkdown>

                    <div className={styles.divider} />

                    <span className={styles.debug_output_section_title}>Created At: </span>
                    <p>{formatTimestamp(historyItem?.created_at)}</p>
                </div>
            </div>
        </>
    );
}

function MultiGradeBody() {
    const [essay, setEssay] = useState("");
    const [selectedEssayType, setSelectedEssayType] = useState(null);
    const [showEssayTypeDropdown, setShowEssayTypeDropdown] = useState(false);
    const [showSchoolDropdown, setShowSchoolDropdown] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const essayTypeDropdownRef = useRef(null);
    const schoolDropdownRef = useRef(null);

    const shouldDisableButton =
        essay === "" ||
        selectedEssayType === null ||
        (selectedEssayType?.metadata?.essayType === "supplemental_essay" && selectedSchool === null);
    const handleFormInputChange = (event) => {
        setEssay(event.target.value);
    };

    const handleMultiGradeClick = (event) => {
        event.preventDefault();

        // console.log(essay);
    };

    const handleSetSelectedEssayType = (essayType) => {
        setSelectedEssayType(essayType);
        if (essayType === null || essayType.metadata.essayType !== "supplemental_essay") {
            setSelectedSchool(null);
        }
    };

    const essayTypeDropdownOptions = [
        { name: "Common App", metadata: { essayType: "common_app_essay" } },
        { name: "Supplemental", metadata: { essayType: "supplemental_essay" } },
        { name: "Scholarship", metadata: { essayType: "scholarship_essay" } },
    ];
    const essayTypeDropdown = (
        <Dropdown
            showDropdown={showEssayTypeDropdown}
            setShowDropdown={setShowEssayTypeDropdown}
            items={essayTypeDropdownOptions}
            selectedItem={selectedEssayType}
            setSelectedItem={handleSetSelectedEssayType}
            placeholder={"Select an essay type"}
            dropdownRef={essayTypeDropdownRef}
            allowClear={true}
        />
    );

    const schoolDropdown = (
        <Dropdown
            showDropdown={showSchoolDropdown}
            setShowDropdown={setShowSchoolDropdown}
            items={dropdownSchoolList}
            selectedItem={selectedSchool}
            setSelectedItem={setSelectedSchool}
            placeholder={"Select a school"}
            dropdownRef={schoolDropdownRef}
            searchable={true}
            searchTermMapping={dropdownSchoolListSearchTermMapping}
            allowClear={true}
            disabled={selectedEssayType?.metadata?.essayType !== "supplemental_essay"}
        />
    );

    return (
        <div className={form_styles.form_container}>
            <form className={form_styles.form} onSubmit={(event) => handleMultiGradeClick(event)}>
                <div className={form_styles.form_section}>
                    <label htmlFor="essay">Essay</label>
                    <textarea
                        id="essay"
                        type="text"
                        placeholder="1234567890"
                        onChange={handleFormInputChange}
                        required
                    />
                </div>
                <div className={form_styles.side_by_side}>
                    <div className={form_styles.form_section}>
                        <label htmlFor="essay_type">Essay Type</label>
                        {essayTypeDropdown}
                    </div>
                    <div className={form_styles.form_section}>
                        <label htmlFor="school">School (if supplemental essay is selected)</label>
                        {schoolDropdown}
                    </div>
                </div>
                <BaseButton
                    className={`${form_styles.form_button} ${shouldDisableButton && form_styles.disabled_form_button}`}
                    disabled={shouldDisableButton}
                >
                    Run Multi-Grade
                </BaseButton>
            </form>
        </div>
    );
}

export default function DebugPage() {
    return (
        <div className={styles.page_container}>
            <Card title={"Debug"} body={<DebugBody />} />
            <Card title={"Test LLM Prompt Changes"} body={<MultiGradeBody />} />
        </div>
    );
}
