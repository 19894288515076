import { useCallback, useEffect, useMemo, useState } from "react";
import {
    createApplication,
    createCollegeEssay,
    deleteCustomPrompt,
    fetchApplicationCollegeEssays,
    fetchChatHistory,
    fetchCommonAppApplication,
    fetchPlaygroundApplication,
    fetchPlaygroundPrompts,
    fetchPreviousEssayGradeSubmissions,
    fetchScholarshipsApplication,
    fetchScholarshipsPrompts,
    gradeCommonAppEssay,
    gradeScholarshipEssay,
    gradeSupplementalEssay,
    sendEmailVerification,
    submitBrainstormRequest,
    submitProgressCheckRequest,
    updateCustomPrompt,
} from "../../api/apiCalls.js";
import essayPrompts from "./essayPrompts.js";
import { schoolInfo } from "./schoolList.js";
import { v5 as uuidv5 } from "uuid";
import useUserContext from "../auth/useUserContext.js";
import { ApplicationContext } from "./ApplicationContext";

import { STATIC_ATHENA_NAMESPACE_UUID, ExtractErrorMessage } from "../main/utils/utils.js";
import { toast } from "react-toastify";

export default function ApplicationProvider({ children }) {
    const { userProfile, frontendActiveProfile, isIEC } = useUserContext();

    // Consider useMemo in the future if performance becomes an issue
    const [applicationId, setApplicationId] = useState(null);
    const [applicationPageLoading, setApplicationPageLoading] = useState(true);

    const handleSetApplicationId = (id) => {
        setApplicationId(id);
    };

    // Page states
    const [isUserVerified, setisUserVerified] = useState(true);

    // Editor States
    const [editorStateJSON, setEditorStateJSON] = useState(null);
    const [editorStateStr, setEditorStateStr] = useState("");
    const [isEditorStateSaving, setIsEditorStateSaving] = useState(false);

    // Application College Essay states
    const [applicationInfo, setApplicationInfo] = useState(null); // {"schoolName": "California Institute of Technology", "schoolCode": "caltech", "admissionPlan": "regular_decision", "assets": {"bannerImage": <something>, "logo": <something>}} <-- we really need typescript after july 15 launch
    const [promptToEssayIdMapping, setPromptToEssayIdMapping] = useState(null);
    const [prompts, setPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null); // {"id": "2024_caltech_0", "title": "Academic Question", "text": "Something", "maxWordCount": 200, ...}
    const [essayId, setEssayId] = useState(null);

    // Athena Sidebar states
    const [showSidebar, setShowSidebar] = useState(false);
    const [activeSidebarTab, setActiveSidebarTab] = useState({
        mainTab: "guidance",
        subTab: "",
    });
    const [messages, setMessages] = useState([]);
    const [essaySubmission, setEssaySubmission] = useState("");
    const [isBrainstormLoading, setIsBrainstormLoading] = useState(false);
    const [isProgressCheckLoading, setIsProgressCheckLoading] = useState(false);
    const [showPreviousSubmissionDisplay, setShowPreviousSubmissionDisplay] = useState(false);
    const [showV2PreviousSubmissionDisplay, setShowV2PreviousSubmissionDisplay] = useState(false);

    // Essay Submission states
    const [currentRequestId, setCurrentRequestId] = useState(""); // Using this as report_card_v2_id in GoogleDocComments.js
    const [isEssaySubmissionLoading, setIsEssaySubmissionLoading] = useState(false);
    const [isV2EssaySubmissionLoading, setIsV2EssaySubmissionLoading] = useState(false);
    const [gradeErrorMessage, setGradeErrorMessage] = useState("");
    const [v2GradeErrorMessage, setV2GradeErrorMessage] = useState("");
    const [modelResponse, setModelResponse] = useState("");
    const [v2ModelResponseJSON, setV2ModelResponseJSON] = useState({});
    const [previousSubmissions, setPreviousSubmissions] = useState([]);
    const [v2PreviousSubmissions, setV2PreviousSubmissions] = useState([]);

    // Playground states
    const [isPlaygroundLoading, setIsPlaygroundLoading] = useState(true);

    // Scholarships states
    const [isScholarshipsLoading, setIsScholarshipsLoading] = useState(true);

    const initialMessage = useMemo(
        () => ({
            id: STATIC_ATHENA_NAMESPACE_UUID,
            message:
                "Hello! I'm Athena, your personal college essay assistant. Get started by using the Brainstorm, Progress Check, or Grade buttons at the bottom of your screen!",
            sender: "athena",
            messageType: null,
        }),
        []
    );

    /* API CALL SECTION */
    const handleSubmitBrainstormRequest = () => {
        // Update UI
        updateSidebarTab("guidance");

        setIsBrainstormLoading(true);
        // Make chat completions api call here!
        const formattedData = {
            school_code: applicationInfo.schoolCode,
            essay_prompt: selectedPrompt.text,
            current_progress: editorStateStr,
            prompt_id: selectedPrompt.id,
        };
        submitBrainstormRequest(formattedData)
            .then((response) => {
                const formattedBrainstormMessage = {
                    id: response.data.id,
                    message: response.data.brainstorm,
                    sender: "athena",
                    messageType: response.data.message_type,
                };
                setMessages((oldMessages) => [formattedBrainstormMessage, ...oldMessages]);
                if (frontendActiveProfile?.membership_plan === "free") {
                    toast.success(
                        `You have ${response.data.weekly_ai_suggestions_remaining} of 5 free weekly AI suggestions remaining. Upgrade for unlimited progress checks!`
                    );
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.error);
            })
            .finally(() => setIsBrainstormLoading(false));
    };

    const handleEmailVerification = () => {
        const formattedData = {
            email: userProfile?.email, // Always use user email
        };
        sendEmailVerification(formattedData)
            .then((_) => toast.success("Email Successfully Sent!"))
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    const handleSubmitGradeRequest = (essaySnapshot) => {
        // Update UI
        updateSidebarTab(isIEC ? "reviewV2" : "review");
        setShowPreviousSubmissionDisplay(false);
        setShowV2PreviousSubmissionDisplay(false);

        function setAppropriateEssaySubmissionLoadingState(state) {
            if (isIEC) {
                setIsV2EssaySubmissionLoading(state);
            } else {
                setIsEssaySubmissionLoading(state);
            }
        }

        // Make grade request api call here!
        setAppropriateEssaySubmissionLoadingState(true);
        setEssaySubmission(essaySnapshot);
        setModelResponse("");
        setGradeErrorMessage("");
        setV2ModelResponseJSON({});
        setV2GradeErrorMessage("");

        if (!frontendActiveProfile?.is_verified) {
            setisUserVerified(false);
            setAppropriateEssaySubmissionLoadingState(false);
            throw new Error("User must verify their email before clicking grade");
        }

        const schoolCode = applicationInfo.schoolCode;
        const timestamp = new Date().toISOString();
        const essayId = promptToEssayIdMapping[selectedPrompt.id];
        const idempotencyKey = uuidv5(
            `${schoolCode}-${essayId}-${timestamp.slice(0, 19)}`,
            STATIC_ATHENA_NAMESPACE_UUID
        );

        if (schoolCode === "common_app") {
            // Common App essay
            const formattedData = {
                school_code: "flagship", // This is *model* school code (SchoolCode) rather than application school code (ApplicationSchoolCode)
                application_school_code: schoolCode,
                idempotency_key: idempotencyKey,
                common_app_essay: {
                    response: essaySnapshot,
                },
                essay_id: essayId,
            };
            gradeCommonAppEssay(formattedData)
                .then((response) => {
                    setCurrentRequestId(response.data.request_id);
                })
                .catch((error) => {
                    setAppropriateEssaySubmissionLoadingState(false);
                    setGradeErrorMessage(ExtractErrorMessage(error));
                    setV2GradeErrorMessage(ExtractErrorMessage(error));
                });
            return;
        }

        if (schoolCode === "scholarships") {
            // Scholarships essay
            const formattedData = {
                school_code: "flagship",
                application_school_code: schoolCode,
                idempotency_key: idempotencyKey,
                scholarship_essay: {
                    prompt: selectedPrompt.text,
                    response: essaySnapshot,
                },
                max_word_count: selectedPrompt.maxWordCount,
                essay_id: essayId,
            };

            gradeScholarshipEssay(formattedData)
                .then((response) => {
                    setCurrentRequestId(response.data.request_id);
                })
                .catch((error) => {
                    setAppropriateEssaySubmissionLoadingState(false);
                    setGradeErrorMessage(ExtractErrorMessage(error));
                    setV2GradeErrorMessage(ExtractErrorMessage(error));
                });

            return;
        }

        // Playground only offers flagship models. Otherwise, use pro model for pro users and flagship for other users.
        const modelSchoolCode =
            schoolCode === "playground"
                ? "flagship"
                : frontendActiveProfile?.membership_plan === "pro"
                ? schoolCode
                : "flagship";
        const formattedData = {
            // Don't automatically default to pro school code
            school_code: modelSchoolCode,
            application_school_code: schoolCode,
            idempotency_key: idempotencyKey,
            supplemental_essay: {
                prompt: selectedPrompt.text,
                response: essaySnapshot,
            },
            max_word_count: selectedPrompt.maxWordCount,
            essay_id: essayId,
            supplemental_essay_category: selectedPrompt.supplementalEssayCategory,
        };

        gradeSupplementalEssay(formattedData)
            .then((response) => {
                setCurrentRequestId(response.data.request_id);
            })
            .catch((error) => {
                setAppropriateEssaySubmissionLoadingState(false);
                setGradeErrorMessage(ExtractErrorMessage(error));
                setV2GradeErrorMessage(ExtractErrorMessage(error));
            });
    };

    // TODO (Eugene): We could prob consolidate this with brainstorm since their
    // api calls are very similar
    const handleSubmitProgressCheckRequest = () => {
        // Update UI
        updateSidebarTab("guidance");

        setIsProgressCheckLoading(true);
        const formattedData = {
            school_code: applicationInfo.schoolCode,
            essay_prompt: selectedPrompt.text,
            current_progress: editorStateStr,
            essay_category: selectedPrompt.supplementalEssayCategory,
            essay_id: essayId,
            max_word_count: selectedPrompt.maxWordCount,
        };
        submitProgressCheckRequest(formattedData)
            .then((response) => {
                const formattedProgressCheckMessage = {
                    id: response.data.id,
                    message: response.data.progress_check,
                    sender: "athena",
                    messageType: response.data.message_type,
                };
                setMessages((oldMessages) => [formattedProgressCheckMessage, ...oldMessages]);
                if (frontendActiveProfile?.membership_plan === "free") {
                    toast.success(
                        `You have ${response.data.weekly_ai_suggestions_remaining} of 5 free weekly AI suggestions remaining. Upgrade for unlimited progress checks!`
                    );
                }
            })
            .catch((error) => toast.error(error?.response?.data?.error))
            .finally(() => setIsProgressCheckLoading(false));
    };

    /* COMMON APP SECTION */
    const handleInitializeCommonApp = () => {
        // Set applicationId for Application Context
        fetchCommonAppApplication()
            .then((response) => {
                const commonApp = response.data.application;
                if (!("id" in commonApp)) {
                    const formData = {
                        school_code: "common_app",
                        admission_plan: "regular_decision", // all common apps are set to regular decision
                    };
                    createApplication(formData)
                        .then((response) => {
                            handleSetApplicationId(response.data.application.id);
                        })
                        .catch((error) => toast.error(error?.response?.data?.error));
                } else {
                    handleSetApplicationId(commonApp.id);
                }
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    /* PLAYGROUND SECTION */
    const handleInitializePlayground = () => {
        // Set applicationId for Application Context
        fetchPlaygroundApplication()
            .then((response) => {
                const playgroundApp = response.data.application;
                if (!("id" in playgroundApp)) {
                    const formData = {
                        school_code: "playground",
                        admission_plan: "regular_decision", // all playground are temporarily set to regular_decision
                    };
                    createApplication(formData)
                        .then((response) => {
                            handleSetApplicationId(response.data.application.id);
                        })
                        .catch((error) => toast.error(error?.response?.data?.error));
                } else {
                    handleSetApplicationId(playgroundApp.id);
                }
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    // Special handler to fetch playground prompts. Prompt selection is handled in the Playground component
    const handleFetchPlaygroundPrompts = useCallback((applicationId) => {
        fetchPlaygroundPrompts(applicationId)
            .then((response) => {
                const promptToEssayInfo = response.data.prompt_to_essay_info;
                const schoolPrompts = Object.keys(promptToEssayInfo).map((promptId) => {
                    const essayInfo = promptToEssayInfo[promptId];
                    return {
                        id: promptId,
                        title: essayInfo["prompt_title"],
                        text: essayInfo["prompt"],
                        maxWordCount: essayInfo["max_word_count"],
                        supplementalEssayCategory: "Uncategorized",
                    };
                });
                setPrompts(schoolPrompts);
            })
            .catch((error) => toast.error(`Error fetching playground prompts: ${error?.response?.data?.error}`))
            .finally(() => setIsPlaygroundLoading(false));
    }, []);

    const handleCreatePlaygroundEssay = (promptTitle, promptText, maxWordCount) => {
        const promptId =
            "playground_" +
            uuidv5(`${promptTitle}-${promptText.slice(0, 16)}-${maxWordCount}`, STATIC_ATHENA_NAMESPACE_UUID);
        const formData = {
            application_id: applicationId,
            prompt_id: promptId,
            essay: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
            // Playground fields
            prompt_title: promptTitle,
            prompt: promptText,
            max_word_count: maxWordCount,
        };
        // TODO (Eugene): Switch this over to createCustomPrompt()
        toast
            .promise(createCollegeEssay(formData), {
                pending: "Creating custom prompt...",
                success: "Prompt created!",
                error: "Error creating prompt",
            })
            .then((response) => {
                return fetchApplicationCollegeEssays(applicationId);
            })
            .then((response) => {
                setPromptToEssayIdMapping(response.data.prompt_to_essay_id_mapping);
                setPrompts((oldPrompts) => [
                    ...oldPrompts,
                    {
                        id: promptId,
                        title: promptTitle,
                        text: promptText,
                        maxWordCount: maxWordCount,
                        supplementalEssayCategory: "Uncategorized",
                    },
                ]);
            })
            .catch((error) => toast.error(`Error creating new essay ${error?.response?.data?.error}`));
    };

    const handleEditPlaygroundEssay = (promptId, promptTitle, promptText, maxWordCount) => {
        const formData = {
            prompt_title: promptTitle,
            prompt: promptText,
            max_word_count: maxWordCount,
        };
        const essayId = promptToEssayIdMapping[promptId];
        toast
            .promise(updateCustomPrompt(essayId, formData), {
                pending: "Updating prompt...",
                success: "Prompt updated!",
                error: "Error updating prompt",
            })
            .then((_) => {
                setPrompts((oldPrompts) =>
                    oldPrompts.map((prompt) => {
                        if (prompt.id === promptId) {
                            return {
                                id: promptId,
                                title: promptTitle,
                                text: promptText,
                                maxWordCount: maxWordCount,
                                supplementalEssayCategory: "Uncategorized",
                            };
                        }
                        return prompt;
                    })
                );
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    const handleDeletePlaygroundEssay = (promptId) => {
        const essayId = promptToEssayIdMapping[promptId];
        toast
            .promise(deleteCustomPrompt(essayId), {
                pending: "Deleting prompt...",
                success: "Prompt deleted!",
                error: "Error deleting prompt",
            })
            .then((response) => {
                setPrompts((oldPrompts) => oldPrompts.filter((prompt) => prompt.id !== promptId));
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    /* SCHOLARSHIPS SECTION */
    const handleInitializeScholarships = useCallback(() => {
        // Set applicationId for Application Context
        fetchScholarshipsApplication()
            .then((response) => {
                const scholarshipsApp = response.data.application;
                if (!("id" in scholarshipsApp)) {
                    const formData = {
                        school_code: "scholarships",
                        admission_plan: "regular_decision", // all scholarships are temporarily set to regular decision
                    };
                    createApplication(formData)
                        .then((response) => {
                            handleSetApplicationId(response.data.application.id);
                        })
                        .catch((error) => toast.error(error?.response?.data?.error));
                } else {
                    handleSetApplicationId(scholarshipsApp.id);
                }
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    }, []);

    // Special handler to fetch scholarships prompts. Prompt selection is handled in the Scholarships component
    const handleFetchScholarshipsPrompts = useCallback((applicationId) => {
        fetchScholarshipsPrompts(applicationId)
            .then((response) => {
                const promptToEssayInfo = response.data.prompt_to_essay_info;
                const schoolPrompts = Object.keys(promptToEssayInfo).map((promptId) => {
                    const essayInfo = promptToEssayInfo[promptId];
                    return {
                        id: promptId,
                        title: essayInfo["prompt_title"],
                        text: essayInfo["prompt"],
                        maxWordCount: essayInfo["max_word_count"],
                        supplementalEssayCategory: "Uncategorized",
                    };
                });
                setPrompts(schoolPrompts);
            })
            .catch((error) => toast.error(`Error fetching scholarships prompts: ${error?.response?.data?.error}`))
            .finally(() => setIsScholarshipsLoading(false));
    }, []);

    const handleCreateScholarshipsEssay = (promptTitle, promptText, maxWordCount) => {
        const promptId =
            "scholarships_" +
            uuidv5(`${promptTitle}-${promptText.slice(0, 16)}-${maxWordCount}`, STATIC_ATHENA_NAMESPACE_UUID);
        const formData = {
            application_id: applicationId,
            prompt_id: promptId,
            essay: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
            // Scholarships fields (same as playground)
            prompt_title: promptTitle,
            prompt: promptText,
            max_word_count: maxWordCount,
        };
        // TODO (Eugene): Switch this over to createCustomPrompt()
        toast
            .promise(createCollegeEssay(formData), {
                pending: "Creating custom prompt...",
                success: "Prompt created!",
                error: "Error creating prompt",
            })
            .then((response) => {
                return fetchApplicationCollegeEssays(applicationId);
            })
            .then((response) => {
                setPromptToEssayIdMapping(response.data.prompt_to_essay_id_mapping);
                setPrompts((oldPrompts) => [
                    ...oldPrompts,
                    {
                        id: promptId,
                        title: promptTitle,
                        text: promptText,
                        maxWordCount: maxWordCount,
                        supplementalEssayCategory: "Uncategorized",
                    },
                ]);
            })
            .catch((error) => toast.error(`Error creating new essay ${error?.response?.data?.error}`));
    };

    const handleEditScholarshipsEssay = (promptId, promptTitle, promptText, maxWordCount) => {
        const formData = {
            prompt_title: promptTitle,
            prompt: promptText,
            max_word_count: maxWordCount,
        };
        const essayId = promptToEssayIdMapping[promptId];
        toast
            .promise(updateCustomPrompt(essayId, formData), {
                pending: "Updating prompt...",
                success: "Prompt updated!",
                error: "Error updating prompt",
            })
            .then((_) => {
                setPrompts((oldPrompts) =>
                    oldPrompts.map((prompt) => {
                        if (prompt.id === promptId) {
                            return {
                                id: promptId,
                                title: promptTitle,
                                text: promptText,
                                maxWordCount: maxWordCount,
                                supplementalEssayCategory: "Uncategorized",
                            };
                        }
                        return prompt;
                    })
                );
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    const handleDeleteScholarshipsEssay = (promptId) => {
        const essayId = promptToEssayIdMapping[promptId];
        toast
            .promise(deleteCustomPrompt(essayId), {
                pending: "Deleting prompt...",
                success: "Prompt deleted!",
                error: "Error deleting prompt",
            })
            .then((response) => {
                setPrompts((oldPrompts) => oldPrompts.filter((prompt) => prompt.id !== promptId));
            })
            .catch((error) => toast.error(error?.response?.data?.error));
    };

    /* APPLICATION SIDEBAR SECTION */
    const DEFAULT_SIDEBAR_TAB = "guidance";
    const updateSidebarTab = (mainTab = DEFAULT_SIDEBAR_TAB, subTab = "") => {
        setShowSidebar(true);
        // Default review sub-tab to report card
        if (mainTab === "review" && subTab === "") {
            subTab = "reportCard";
        }
        setActiveSidebarTab({
            mainTab: mainTab,
            subTab: subTab,
        });
    };
    const toggleSidebar = (toggleState = null) => {
        if (toggleState === null) {
            setShowSidebar((prevShowSidebar) => !prevShowSidebar);
            return;
        }
        setShowSidebar(toggleState);
    };

    /* DATA FETCHING SECTION */
    const handleFetchApplicationCollegeEssays = useCallback(async (applicationId) => {
        return fetchApplicationCollegeEssays(applicationId)
            .then((response) => {
                setPromptToEssayIdMapping(response.data.prompt_to_essay_id_mapping);
                const rawApplicationData = response.data.application_info;
                const schoolCode = rawApplicationData.school_code;
                const isAIAllowed =
                    schoolInfo[schoolCode]?.isAIAllowed !== undefined ? schoolInfo[schoolCode]?.isAIAllowed : true;

                let formattedApplicationInfo = {
                    schoolName: "",
                    schoolCode: schoolCode,
                    assets: {
                        bannerImage: null,
                        logo: null,
                    },
                    admissionPlan: rawApplicationData.admission_plan,
                    isAIAllowed: isAIAllowed,
                };

                // Playground and scholarships apps are an exception. TODO (Eugene): Figure out whether to handle prompt fetching here
                if (schoolCode === "playground") {
                    formattedApplicationInfo["schoolName"] = "Playground";
                } else if (schoolCode === "scholarships") {
                    formattedApplicationInfo["schoolName"] = "Scholarships";
                } else {
                    formattedApplicationInfo["schoolName"] = schoolInfo[schoolCode].schoolName;
                    formattedApplicationInfo["assets"] = schoolInfo[schoolCode].assets;
                }
                setApplicationInfo(formattedApplicationInfo);
            })
            .catch((error) => toast.error(`Error fetching attached essays: ${error?.response?.data?.error}`));
    }, []);

    useEffect(() => {
        if (!applicationId) return;

        handleFetchApplicationCollegeEssays(applicationId).finally(() => setApplicationPageLoading(false));
    }, [applicationId, handleFetchApplicationCollegeEssays]);

    /* UPDATE STATES SECTION */

    // INITIALIZE STATES AFTER DATA FETCHING
    // Get school-specific prompts when school code is fetched from backend
    useEffect(() => {
        if (!applicationInfo) return;

        // Playground and scholarships apps are an exception. We handle prompt setting in Playground.js and Scholarships.js
        if (applicationInfo.schoolCode === "playground") {
            handleFetchPlaygroundPrompts(applicationId);
        } else if (applicationInfo.schoolCode === "scholarships") {
            handleFetchScholarshipsPrompts(applicationId);
        } else {
            // TODO (Eugene): Figure out what to do if application school code is not in our
            // client master list of school prompts
            const schoolCode = applicationInfo.schoolCode;
            const schoolPrompts = essayPrompts[schoolCode]?.prompts;

            setPrompts(schoolPrompts);
            setSelectedPrompt(schoolPrompts && schoolPrompts[0]); // Temp fix for schools that don't exist
        }
    }, [applicationInfo, applicationId, handleFetchPlaygroundPrompts, handleFetchScholarshipsPrompts]);

    // STATE UPDATES DURING USER INTERACTION
    // When prompt ID changes, we also need the essay prompts to change
    useEffect(() => {
        if (!promptToEssayIdMapping) return;
        if (!selectedPrompt) return;

        const promptId = selectedPrompt.id;
        if (!(promptId in promptToEssayIdMapping)) {
            const formData = {
                application_id: applicationId,
                prompt_id: promptId,
                essay: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
            };
            createCollegeEssay(formData)
                .then((response) => {
                    return fetchApplicationCollegeEssays(applicationId);
                })
                .then((response) => {
                    setPromptToEssayIdMapping(response.data.prompt_to_essay_id_mapping);
                })
                .catch((error) => {
                    toast.error(`Error creating new essay: ${error?.response?.data?.error}`);
                });
        }
        setEssayId(promptToEssayIdMapping[selectedPrompt.id]);
    }, [prompts, selectedPrompt, promptToEssayIdMapping, applicationId]);

    // Update sidebar states when essayId changes
    useEffect(() => {
        if (!essayId) {
            setMessages([initialMessage]);
            return;
        }

        fetchChatHistory(essayId)
            .then((response) => {
                const rawChatHistory = response.data.chat_history;
                const formattedChatHistory = rawChatHistory.map((chat) => {
                    return {
                        id: chat.id,
                        message: chat.message,
                        sender: chat.sender,
                        messageType: chat.message_type,
                    };
                });

                if (!formattedChatHistory || formattedChatHistory.length === 0) {
                    setMessages([initialMessage]);
                } else {
                    setMessages([...formattedChatHistory]);
                }
            })
            .catch((error) => {
                toast.error(`Error fetching chat history: ${error?.response?.data?.error}`);
                setMessages([initialMessage]);
            });

        // TODO (Eugene): Update report card when essayId changes
        // Maybe think about how to temporarily store model response snapshots
        setCurrentRequestId("");
        setModelResponse("");
        setV2ModelResponseJSON({});
        setGradeErrorMessage("");
        setV2GradeErrorMessage("");

        // Fetch previous essay submissions for both history and report card tabs
        fetchPreviousEssayGradeSubmissions(essayId, "history")
            .then((response) => {
                const rawPreviousSubmissions = response.data.previous_submissions;
                const formattedPreviousSubmissions = rawPreviousSubmissions.map((prevSubmission) => {
                    return {
                        essayPreview: prevSubmission.essay_preview,
                        date: new Date(prevSubmission.created_at).toLocaleString(),
                        historyId: prevSubmission.id,
                    };
                });
                setPreviousSubmissions(formattedPreviousSubmissions);
            })
            .catch((error) => {
                toast.error(`Error fetching previous submissions: ${error?.response?.data?.error}`);
            });
        fetchPreviousEssayGradeSubmissions(essayId, "report_card_v2")
            .then((response) => {
                const rawPreviousSubmissions = response.data.previous_submissions;
                const formattedPreviousSubmissions = rawPreviousSubmissions.map((prevSubmission) => {
                    return {
                        essayPreview: prevSubmission.essay_preview,
                        date: new Date(prevSubmission.created_at).toLocaleString(),
                        historyId: prevSubmission.id,
                    };
                });
                setV2PreviousSubmissions(formattedPreviousSubmissions);
            })
            .catch((error) => {
                toast.error(`Error fetching previous submissions: ${error?.response?.data?.error}`);
            });
    }, [essayId, setMessages, setCurrentRequestId, setV2PreviousSubmissions, initialMessage]);

    return (
        <ApplicationContext.Provider
            value={{
                applicationId,
                handleSetApplicationId,
                showSidebar,
                toggleSidebar,
                activeSidebarTab,
                applicationInfo,
                setApplicationInfo,

                // Sidebar states
                messages,
                setMessages,
                handleSubmitBrainstormRequest,
                handleSubmitProgressCheckRequest,
                isBrainstormLoading,
                setIsBrainstormLoading,
                isProgressCheckLoading,
                setIsProgressCheckLoading,
                essaySubmission,
                setEssaySubmission,
                showPreviousSubmissionDisplay,
                setShowPreviousSubmissionDisplay,
                showV2PreviousSubmissionDisplay,
                setShowV2PreviousSubmissionDisplay,
                updateSidebarTab,

                // Editor states
                editorStateJSON,
                setEditorStateJSON,
                editorStateStr,
                setEditorStateStr,
                isEditorStateSaving,
                setIsEditorStateSaving,

                // Application College Essay states
                prompts,
                promptToEssayIdMapping,
                essayId,
                selectedPrompt,
                setSelectedPrompt,
                initialMessage,

                // Workaround for Suspense loading
                applicationPageLoading,

                // User Verification
                isUserVerified,
                setisUserVerified,
                handleEmailVerification,

                // Essay Submission
                handleSubmitGradeRequest,
                currentRequestId,
                modelResponse,
                setModelResponse,
                gradeErrorMessage,
                setGradeErrorMessage,
                isEssaySubmissionLoading,
                setIsEssaySubmissionLoading,
                previousSubmissions,
                setPreviousSubmissions,

                // Essay Submission Report Card V2
                v2ModelResponseJSON,
                setV2ModelResponseJSON,
                v2GradeErrorMessage,
                setV2GradeErrorMessage,
                isV2EssaySubmissionLoading,
                setIsV2EssaySubmissionLoading,
                v2PreviousSubmissions,
                setV2PreviousSubmissions,

                // Common App
                handleInitializeCommonApp,

                // Playground
                handleInitializePlayground,
                handleCreatePlaygroundEssay,
                handleDeletePlaygroundEssay,
                handleEditPlaygroundEssay,
                isPlaygroundLoading,

                // Scholarships
                handleInitializeScholarships,
                handleCreateScholarshipsEssay,
                handleEditScholarshipsEssay,
                handleDeleteScholarshipsEssay,
                isScholarshipsLoading,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
}
