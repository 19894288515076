import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Dropdown from "../../base/Dropdown";
import form_styles from "../../../styles/forms/Forms.module.css";
import tools_styles from "../../../styles/consultant/ConsultantToolsPage.module.css";
import styles from "../../../styles/consultant/GradeByLinkPage.module.css";
import BaseButton from "../../base/BaseButton";
import GooglePicker from "../GooglePicker";
import { ReactComponent as LoadingSpinner } from "../../../imgs/loading.svg";

import { dropdownSchoolList, dropdownSchoolListSearchTermMapping } from "../../application/schoolList";
import { ReactComponent as GoogleDocsIcon } from "../../../imgs/google-docs-icon.svg";
import { ReactComponent as CloseIcon } from "../../../imgs/close-icon.svg";

import { fetchGoogleDriveAuthorizationURL, checkGoogleDocsAuthentication } from "../../../api/apiCalls";
import { toast } from "react-toastify";

export default function LinkSubmissionScreen(props) {
    const {
        setScreen,
        googleDoc,
        googleDocLink,
        selectedEssayType,
        selectedSchool,
        setGoogleDoc,
        setGoogleDocLink,
        setSelectedEssayType,
        setSelectedSchool,
        setIsFetchingEssay,
    } = props;

    const [showSchoolDropdown, setShowSchoolDropdown] = useState(false);
    const [showEssayTypeDropdown, setShowEssayTypeDropdown] = useState(false);
    const schoolDropdownRef = useRef(null);
    const essayTypeDropdownRef = useRef(null);

    const [inputMethod, setInputMethod] = useState(""); // "direct", "link", or ""

    const handleGradeClick = (event) => {
        event.preventDefault();

        // Essay grading is handled in EssayFeedbackScreen
        setScreen("essay_feedback");
        setIsFetchingEssay(true);
    };

    const handleSetSelectedEssayType = (essayType) => {
        setSelectedEssayType(essayType);
        if (essayType === null || essayType.metadata.essayType !== "supplemental_essay") {
            setSelectedSchool(null);
        }
    };

    const essayTypeDropdownOptions = [
        { name: "Common App", metadata: { essayType: "common_app_essay" } },
        { name: "Supplemental", metadata: { essayType: "supplemental_essay" } },
        { name: "Scholarship", metadata: { essayType: "scholarship_essay" } },
    ];
    const essayTypeDropdown = (
        <Dropdown
            showDropdown={showEssayTypeDropdown}
            setShowDropdown={setShowEssayTypeDropdown}
            items={essayTypeDropdownOptions}
            selectedItem={selectedEssayType}
            setSelectedItem={handleSetSelectedEssayType}
            placeholder={"Select an essay type"}
            dropdownRef={essayTypeDropdownRef}
            allowClear={true}
        />
    );

    const schoolDropdown = (
        <Dropdown
            showDropdown={showSchoolDropdown}
            setShowDropdown={setShowSchoolDropdown}
            items={dropdownSchoolList}
            selectedItem={selectedSchool}
            setSelectedItem={setSelectedSchool}
            placeholder={"Select a school"}
            dropdownRef={schoolDropdownRef}
            searchable={true}
            searchTermMapping={dropdownSchoolListSearchTermMapping}
            allowClear={true}
            disabled={selectedEssayType?.metadata?.essayType !== "supplemental_essay"}
        />
    );

    const handleSetInputMethod = (method) => {
        // Reset states so that page state either has googleDoc or googleDocLink
        setGoogleDoc({});
        setGoogleDocLink("");
        setInputMethod(method);
    };

    return (
        <>
            <div className={styles.section_container}>
                <h3 className={styles.section_title}>Select an input method</h3>
                <div className={styles.input_method_container}>
                    <div
                        className={`${styles.input_method_option} ${
                            inputMethod === "direct" && styles.input_method_option_active
                        }`}
                        onClick={() => handleSetInputMethod("direct")}
                    >
                        <div className={styles.input_method_option_label}>Directly from Google Docs</div>
                    </div>
                    <div
                        className={`${styles.input_method_option} ${
                            inputMethod === "link" && styles.input_method_option_active
                        }`}
                        onClick={() => handleSetInputMethod("link")}
                    >
                        <div className={styles.input_method_option_label}>Paste Google Docs Link</div>
                    </div>
                </div>
            </div>
            {inputMethod === "direct" && (
                <DirectInputComponent
                    handleGradeClick={handleGradeClick}
                    essayTypeDropdown={essayTypeDropdown}
                    schoolDropdown={schoolDropdown}
                    selectedEssayType={selectedEssayType}
                    selectedSchool={selectedSchool}
                    googleDoc={googleDoc}
                    setGoogleDoc={setGoogleDoc}
                />
            )}
            {inputMethod === "link" && (
                <LinkInputComponent
                    handleGradeClick={handleGradeClick}
                    selectedEssayType={selectedEssayType}
                    selectedSchool={selectedSchool}
                    essayTypeDropdown={essayTypeDropdown}
                    schoolDropdown={schoolDropdown}
                    setGoogleDocLink={setGoogleDocLink}
                    googleDocLink={googleDocLink}
                />
            )}
        </>
    );
}

function DirectInputComponent(props) {
    const {
        selectedEssayType,
        selectedSchool,
        handleGradeClick,
        essayTypeDropdown,
        schoolDropdown,
        googleDoc,
        setGoogleDoc,
    } = props;

    const [error, setError] = useState("");
    const [accessToken, setAccessToken] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const location = useLocation();
    useEffect(() => {
        const fetchAccessToken = async () => {
            const response = await checkGoogleDocsAuthentication();
            setAccessToken(response.data.access_token);
            setIsLoading(false);
        };

        fetchAccessToken();
    }, []);

    const handleConnectToGoogleDrive = async () => {
        // Step 1: Redirect to correct Google Drive OAuth URL
        // Step 2: Once user is connected, redirect back to this page
        try {
            const response = await fetchGoogleDriveAuthorizationURL(location.pathname);
            window.location.href = response.data.url;
        } catch (error) {
            console.error("Error fetching Google Drive authorization URL", error);
        }
    };

    const handleAddFromGoogleDrive = (doc) => {
        // Step 1: Fetch list of Google Docs
        // Step 2: Add the selected Google Doc to the state
        setGoogleDoc(doc);
    };

    const clearDoc = () => {
        setGoogleDoc({});
    };

    const shouldDisableButton =
        googleDoc.id === undefined ||
        selectedEssayType === null ||
        (selectedEssayType?.metadata?.essayType === "supplemental_essay" && selectedSchool === null);

    if (isLoading) {
        return (
            <div className={styles.loading_spinner_container}>
                <LoadingSpinner className={styles.loading_spinner} />
            </div>
        );
    }

    return (
        <div className={styles.direct_input_container}>
            <div className={styles.section_container}>
                <h3 className={styles.section_title}>Getting Started</h3>
                <ol className={styles.getting_started_list}>
                    <li>Connect to Google Drive</li>
                    <li>
                        Select the document containing the essay you want to grade. Ensure there is only one essay in
                        the document.
                    </li>
                    <li>Specify what type of essay</li>
                </ol>
                <GooglePicker
                    accessToken={accessToken}
                    handleAddFromGoogleDrive={handleAddFromGoogleDrive}
                    handleConnectToGoogleDrive={handleConnectToGoogleDrive}
                />
                {googleDoc.id && <DocPreviewCard googleDoc={googleDoc} clearDoc={clearDoc} />}
                <div className={form_styles.form_container}>
                    <form className={form_styles.form} onSubmit={(event) => handleGradeClick(event)}>
                        <div className={form_styles.side_by_side}>
                            <div className={form_styles.form_section}>
                                <label htmlFor="essay_type">Essay Type</label>
                                {essayTypeDropdown}
                            </div>
                            <div className={form_styles.form_section}>
                                <label htmlFor="school">School (if supplemental essay is selected)</label>
                                {schoolDropdown}
                            </div>
                        </div>
                        <div className={form_styles.form_error}>{error}</div>
                        <BaseButton
                            className={`${form_styles.form_button} ${
                                shouldDisableButton && form_styles.disabled_form_button
                            }`}
                            disabled={shouldDisableButton}
                        >
                            Grade
                        </BaseButton>
                    </form>
                </div>
            </div>
        </div>
    );
}

function LinkInputComponent(props) {
    const {
        selectedEssayType,
        selectedSchool,
        handleGradeClick,
        essayTypeDropdown,
        schoolDropdown,
        setGoogleDocLink,
        googleDocLink,
    } = props;

    const [error, setError] = useState("");

    const shouldDisableButton =
        googleDocLink === "" ||
        selectedEssayType === null ||
        (selectedEssayType?.metadata?.essayType === "supplemental_essay" && selectedSchool === null);

    return (
        <div className={styles.link_input_container}>
            <div className={styles.section_container}>
                <h3 className={styles.section_title}>Getting Started</h3>
                <ol className={styles.getting_started_list}>
                    <li>Set the Google Doc's access to "Anyone with the link can view"</li>
                    <li>Ensure there is only one essay in the Google Doc</li>
                    <li>Paste your Google Doc link below</li>
                </ol>
                <div className={form_styles.form_container}>
                    <form className={form_styles.form} onSubmit={(event) => handleGradeClick(event)}>
                        <div className={form_styles.form_long_section}>
                            <label htmlFor="google_doc_link">Google Doc Link</label>
                            <input
                                type="text"
                                id="google_doc_link"
                                onChange={(event) => setGoogleDocLink(event.target.value)}
                            />
                        </div>
                        <div className={form_styles.side_by_side}>
                            <div className={form_styles.form_section}>
                                <label htmlFor="essay_type">Essay Type</label>
                                {essayTypeDropdown}
                            </div>
                            <div className={form_styles.form_section}>
                                <label htmlFor="school">School (if supplemental essay is selected)</label>
                                {schoolDropdown}
                            </div>
                        </div>
                        <div className={form_styles.form_error}>{error}</div>
                        <BaseButton
                            className={`${form_styles.form_button} ${
                                shouldDisableButton && form_styles.disabled_form_button
                            }`}
                            disabled={shouldDisableButton}
                        >
                            Grade
                        </BaseButton>
                    </form>
                </div>
            </div>
        </div>
    );
}

function DocPreviewCard(props) {
    const { googleDoc, clearDoc } = props;
    return (
        <div className={styles.doc_preview_card}>
            <GoogleDocsIcon className={styles.doc_preview_card_icon} />
            <div className={styles.doc_preview_content}>
                <div className={styles.doc_preview_card_title}>{googleDoc.name}</div>
                <a
                    className={styles.doc_preview_card_preview_link}
                    href={`https://docs.google.com/document/d/${googleDoc.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Open in Google Docs
                </a>
            </div>
            <CloseIcon className={styles.doc_preview_card_close_icon} onClick={clearDoc} />
        </div>
    );
}
