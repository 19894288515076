import styles from "../../styles/consultant/ConsultantToolsPage.module.css";
import { ReactComponent as GlobeIcon } from "../../imgs/globe-icon.svg";
import { ReactComponent as SettingsIcon } from "../../imgs/service-icon.svg";
import { useNavigate } from "react-router-dom";
function Card({ title, description, link, icon }) {
    const navigate = useNavigate();

    return (
        <div className={styles.card} onClick={() => navigate(link)}>
            {icon}
            <div className={styles.card_content}>
                <h2 className={styles.card_title}>{title}</h2>
                <p className={styles.card_description}>{description}</p>
            </div>
        </div>
    );
}

export default function ConsultantToolsPage() {
    const tools = [
        {
            title: "Grade by Link",
            description: "Grade your student's essay straight from Google Docs",
            link: "/consultant/tools/grade-by-link",
            icon: <GlobeIcon className={styles.card_icon} />,
        },
        {
            title: "Settings",
            description: "Manage your account settings",
            link: "/consultant/settings",
            icon: <SettingsIcon className={styles.card_icon} />,
        },
    ];
    return (
        <div className={styles.page_container}>
            <div className={styles.page_header}>
                <h1 className={styles.title}>IEC Tools</h1>
            </div>
            <div className={styles.tools_container}>
                {tools.map((tool, index) => (
                    <Card key={index} {...tool} />
                ))}
            </div>
        </div>
    );
}
