import styles from "../../styles/auth/AuthPage.module.css";
import useAuthContext from "./useAuthContext";
import whiteAthenaV2 from "../../imgs/white_athena_v2.png";
import blackAthenaV2 from "../../imgs/black-athena-v2.png";
import BaseButton from "../base/BaseButton";
import Loading from "../base/Loading";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function ResetPasswordPage() {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetPasswordErrors, setResetPasswordErrors] = useState({});
    const { handleResetPassword, handlePasswordResetLinkCheck } = useAuthContext();

    const [searchParams] = useSearchParams();
    const userId = searchParams.get("user_id");
    const token = searchParams.get("token");
    const [isLoading, setIsLoading] = useState(true);
    const [canUserResetPassword, setCanUserResetPassword] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        handlePasswordResetLinkCheck(userId, token)
            .then((_) => {
                setCanUserResetPassword(true);
                setIsLoading(false);
            })
            .catch((_) => {
                setCanUserResetPassword(false);
                setIsLoading(false);
            });
    }, [userId, token]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            user_id: userId,
            password1: e.target.password1.value,
            password2: e.target.password2.value,
            token: token,
        };
        setIsSubmitting(true);
        handleResetPassword(formData)
            .then((_) => setIsSubmitting(false))
            .catch((error) => {
                setResetPasswordErrors({ ...error?.response?.data?.error });
                setIsSubmitting(false);
            });
    };

    const body = canUserResetPassword ? (
        <div className={styles.reset_password_body}>
            <div className={styles.auth_page_header}>
                <img src={blackAthenaV2} alt="Black Athena logo" onClick={() => navigate("/")} />
                <h1>Reset Password</h1>
                <p>Enter the new password for your account and confirm the password.</p>
            </div>
            <div className={styles.reset_password_form_inputs}>
                <form onSubmit={handleSubmit}>
                    {resetPasswordErrors.non_field_errors && (
                        <div className={styles.error}>{resetPasswordErrors.non_field_errors}</div>
                    )}
                    <div className={styles.form_input}>
                        <label htmlFor="password1">New Password</label>
                        <input type="password" id="password1" name="password1" placeholder="New Password" />
                        {resetPasswordErrors.password1 && (
                            <div className={styles.error}>{resetPasswordErrors.password1}</div>
                        )}
                    </div>
                    <div className={styles.form_input}>
                        <label htmlFor="password2">Confirm Password</label>
                        <input type="password" id="password2" name="password2" placeholder="Confirm Password" />
                        {resetPasswordErrors.password2 && (
                            <div className={styles.error}>{resetPasswordErrors.password2}</div>
                        )}
                    </div>
                    <BaseButton className={styles.auth_action_button} disabled={isSubmitting}>
                        Reset Password
                    </BaseButton>
                </form>
            </div>
            <div className={styles.reset_password_footer}>
                <Link to="/login" className={styles.link}>
                    Back to Log In
                </Link>
            </div>
        </div>
    ) : (
        <div>Expired or invalid link. Please try again or reach out to support@athenaco.ai if you have questions.</div>
    );

    return isLoading ? <Loading /> : body;
}
